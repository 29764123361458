import { configureStore } from "@reduxjs/toolkit";
import companyReducer from "./companyslice";
import profileReducer from "./profileslice";

export default configureStore({
  reducer: {
    company: companyReducer,
    profile: profileReducer,
  },
});
