import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Container, Row } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { useMutation } from "@apollo/client";
import { QUICK_SIGNUP, RETRIEVE_INFO_FROM_EMAIL } from "../../GraphQL/mutations";
import { Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ReCAPTCHA from "react-google-recaptcha";
import { isValidPhoneNumber } from "react-phone-number-input";
import { isValidEmail } from "../utils";
import { tr } from "../labels";
import LanguageDropdown from "../dropdownLanguage";
import DownloadComponent from "../download/downloadComponent";
import { getCurrentLanguage } from "../Translation";
import useUpdateTranslations from "../Translation";
import { SignalWifiOff } from "@mui/icons-material";

const QuickSignupComponent = ({ companyName, welcome, logoUrl}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    companyName: companyName,
  });
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [signup] = useMutation(QUICK_SIGNUP, { fetchPolicy: "no-cache" });
  const [retrieveInfoFromEmail] = useMutation(RETRIEVE_INFO_FROM_EMAIL, { fetchPolicy: "no-cache" });

  let errorsObj = { password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [isSigned, setIsSigned] = useState(false);
  const [captcha, setCaptcha] = useState(true);
  const [canSignup, setCanSignup] = useState(true);

  const onSignup = () => {
    setCanSignup(false)
    signup({
        variables: formData
    }).then(({ data }) => {
        if (data.quickSignup.ok) {
            setIsSigned(true)
        }
        else {
            setCanSignup(true)
            toast.error(data.quickSignup.error);
        }
    })
    }
  const setPhoneNumber = (value) => {
    setFormData({ ...formData, phoneNumber: value });
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const prefillValuesFromEmail = (e) => {
    retrieveInfoFromEmail({
        variables: {
            email: formData.email,
            }}).then(({ data }) => {
                if (data.retrieveInfoFromEmail.ok) {
                    setFormData({
                        ...formData,
                        firstName: formData.firstName === "" ? data.retrieveInfoFromEmail.firstName: formData.firstName,
                        lastName: formData.lastName === "" ? data.retrieveInfoFromEmail.lastName: formData.lastName,
                        phoneNumber: formData.phoneNumber === "" ? "+" + data.retrieveInfoFromEmail.phoneNumber: formData.phoneNumber,
                    });
                }
            })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(!canSignup) { return }
    console.log(formData);
    let error = false;
    const errorObj = { ...errorsObj };

    if (!termsAccepted) {
      errorObj.termsAccepted = "You need to accept the terms and conditions";
      error = true;
    }

    if (!formData.companyName) {
      errorObj.companyName = "Company name is required ";
      error = true;
    }
    if (!formData.phoneNumber) {
      errorObj.phoneNumber = "Phone number is required ";
      error = true;
    } else if (!isValidPhoneNumber(formData.phoneNumber)) {
      errorObj.phoneNumber = "Phone number is not valid ";
      error = true;
    }

    if (!formData.email) {
      errorObj.email = "Email is required ";
      error = true;
    }
    if (!formData.firstName) {
      errorObj.firstName = "First name is required ";
      error = true;
    }
    if (!formData.lastName) {
      errorObj.lastName = "Last name is required ";
      error = true;
    }
    if (!isValidEmail(formData.email)) {
      errorObj.email = "Email is not valid ";
      error = true;
    }
    if (!captcha) {
        errorObj.captcha = "Please complete the captcha to continue";
        error = true;
      }
      setErrors(errorObj);

    if (!error) {
      onSignup();
    }
  };
  const handleTermsAccepted = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const shouldDisplayLanguageDropdown = false

  const link = "https://healthmov.page.link/jofZ";

  const [isRedirecting, setIsRedirecting] = useState(false);

  const updateTranslations = useUpdateTranslations();

  useEffect(() => {
    if (!isSigned) return;

    const isMobile = /iPhone|iPad|iPod|Android/i.test(
      window.navigator.userAgent
    );

    if (isMobile) {
      setIsRedirecting(true);
      setTimeout(() => {
        window.location.href = link;
      }, 3000);
    }

    updateTranslations(getCurrentLanguage());
  }, [isSigned, updateTranslations]);

  return (
    <>
      <Container fluid className="app-container">
        <Row className="justify-content-center align-items-center min-vh-80">
          <div className="Login-Logo" style={{ marginTop: "50px"}}>
            <img src={logoUrl} alt="" />
          </div>
          <div className="Login-Btn-Cust-Form">
            {isSigned ? (
              <>
                {isRedirecting ? (
                  <>
                    <h2>{tr("signUpCompleteLabel")}</h2>
                    <p>{tr("redirectLabel")}</p>
                    <button
                      className="btn-main"
                      onClick={() => (window.location.href = link)}
                    >
                      {tr("downloadLabel")}
                    </button>
                  </>
                ) : (
                  <DownloadComponent message="Sign-up completed!" />
                )}
              </>
            ) : (
              <>
                <h2>{welcome}</h2>

                <form className="Login-Form">
                    <div>
                    <label className="form-label" htmlFor="email">
                      {tr("yourEmailLabel")}
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      id="email"
                      name="email"
                      required
                      value={formData.email}
                      onChange={handleChange}
                      onBlur={prefillValuesFromEmail}
                    />
                    {errors.email && (
                      <div className="error-msg">{errors.email}</div>
                    )}
                  </div>
                  <div>
                    <label className="form-label" htmlFor="firstName">
                      {tr("yourNameLabel")}
                    </label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          className="form-control"
                          type="text"
                          id="firstName"
                          name="firstName"
                          placeholder={tr("firstNameLabel")}
                          required
                          value={formData.firstName}
                          onChange={handleChange}
                        />
                        {errors.firstName && (
                          <div className="error-msg">{errors.firstName}</div>
                        )}
                      </div>
                      <div className="col-6">
                        <input
                          className="form-control"
                          type="text"
                          id="lastName"
                          name="lastName"
                          placeholder={tr("lastNameLabel")}
                          required
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                        {errors.lastName && (
                          <div className="error-msg">{errors.lastName}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="form-label">
                      {tr("phoneNumberLabel")}
                    </label>
                    <PhoneInput
                      className="form-control"
                      placeholder={tr("phoneNumberLabel")}
                      value={formData.phoneNumber || ""}
                      id="phoneNumber"
                      required
                      withCountryCallingCode
                      defaultCountry="AE"
                      onChange={setPhoneNumber}
                    />
                    {errors.phoneNumber && (
                      <div className="error-msg">{errors.phoneNumber}</div>
                    )}
                  </div>
                  <div className="t_and_c">
                    <label className="form-label" htmlFor="terms-checkbox">
                      <input
                        type="checkbox"
                        id="terms-checkbox"
                        name="termsAccepted"
                        checked={termsAccepted}
                        onChange={handleTermsAccepted}
                      />
                      {tr("agreeLabel")}{" "}
                      <a
                        href="https://www.healthmov.com/terms-and-conditions/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {tr("termsLabel")}
                      </a>
                      .
                    </label>
                    {errors.termsAccepted && (
                      <div className="error-msg">{errors.termsAccepted}</div>
                    )}
                  </div>
                  {/* <div className="row mt15 justify-content-center">
                    <ReCAPTCHA
                      sitekey="6LezS5AjAAAAAB6s6cmCpVyBfxtYakMOiJm2pnrq"
                      onChange={setCaptcha}
                      className="captcha"
                    />
                    {errors.captcha && (
                      <div className="error-msg">{errors.captcha}</div>
                    )}
                  </div>
 */}
                  <button className="btn-main" onClick={handleSubmit}>
                    {tr("signUpLabel")}
                  </button>
                </form>
              </>
            )}
          </div>
          <div style={{ textAlign: "right" }}>
            {shouldDisplayLanguageDropdown && <LanguageDropdown log={true} />}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default QuickSignupComponent;
