import "./App.css";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import LoginSSOPage from "./pages/loginSSOPage";
import HomePage from "./pages/homePage";
import HealthPage from "./pages/healthPage";
import EngagementPage from "./pages/engagementPage";
import ReferPage from "./pages/referPage";
import ParentReportPage from "./pages/parentReportPage";
import BillingPage from "./pages/billingPage";
import UsersPage from "./pages/usersPage";
import DependentsPage from "./pages/dependentsPage";
import UserProfile from "./pages/userProfile";
import AppLayout from "./AppLayout";
import MaintenanceLayout from "./MaintenanceLayout";
import ForgotPasswordPage from "./pages/forgotPasswordPage";
import CreatePasswordPage from "./pages/createPasswordPage";
import SignupPage from "./pages/signupPage";
import HollardWellnessDaySignupPage from "./pages/hollardWellnessDaySignupPage";
import GitexSignupPage from "./pages/gitexSignupPage";
import WaitingPage from "./pages/waitingPage";
import SuccessPage from "./pages/billing/successPage";
import CanceledPage from "./pages/billing/canceledPage";
import AcceptInvitationPage from "./pages/acceptInvitationPage";
import DownloadPage from "./pages/downloadPage";
import redirectPage from "./pages/redirectPage";
import InvoicePage from "./pages/InvoicePage";
import { Redirect } from "react-router-dom";
import * as am5 from "@amcharts/amcharts5";
import { headerVisible } from "./components/utils";

am5.addLicense(process.env.REACT_APP_AMCHARTS_LICENSE_KEY);
const token = localStorage.getItem("token");
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message, location, path }) => {
      return alert(`Graphql error ${message}`);
    });
  }
});

const link = from([
  errorLink,
  new HttpLink({
    uri: process.env.REACT_APP_API_URL,
    // fetch: (...pl) => {
    //   if (!DEBUG) return fetch(...pl)
    //   const [_, options] = pl
    //   const body = JSON.parse(options.body)
    //   console.log(`📡${body.operationName || ''}\n${body.query}`, body.variables)
    //   return fetch(...pl)
    // },
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});

function App() {
  if (!token && headerVisible()) {
    if (window.location.pathname === "/download") {
      return <DownloadPage />;
    }
    if (window.location.pathname !== "/") {
      window.location.href = "/";
    }
  }
  return (
    <div className="App">
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Switch>
            <MaintenanceLayout>
              <Route path="/signup" exact component={SignupPage} />
              <Route path="/hollard-wellness-day" exact component={HollardWellnessDaySignupPage} />
              <Route path="/hollard-moz" exact component={HollardWellnessDaySignupPage} />
              <Route path="/gitex-2023" exact component={GitexSignupPage} />
              <Route path="/waiting" exact component={WaitingPage} />
              <Route path="/billing-success" exact component={SuccessPage} />
              <Route path="/billing-canceled" exact component={CanceledPage} />
              <Route
                path="/accept_invitation"
                exact
                component={AcceptInvitationPage}
              />
              {token ? (
                <>
                  <AppLayout>
                    <Route path="/" exact component={HomePage} />
                    <Route path="/home" exact component={HomePage} />
                    <Route path="/users" exact component={UsersPage} />
                    <Route path="/dependents" exact component={DependentsPage} />
                    <Route
                      path="/account-billing"
                      exact
                      component={BillingPage}
                    />
                    <Route path="/health" exact component={HealthPage} />
                    <Route
                      path="/engagement"
                      exact
                      component={EngagementPage}
                    />
                    <Route path="/refer" exact component={ReferPage} />
                    <Route
                      path="/reports-parent"
                      exact
                      component={ParentReportPage}
                    />
                    <Route path="/profile" exact component={UserProfile} />
                    <Route path="/download" exact component={DownloadPage} />
                    <Route
                      path="/redirect/:token"
                      exact
                      component={redirectPage}
                    />
                    <Route path="/invoices" exact component={InvoicePage} />
                  </AppLayout>
                  <Route exact path="/">
                    <Redirect to="/home" />
                  </Route>
                  <Route exact path="/login">
                    <Redirect to="/home" />
                  </Route>
                  <Route exact path="/signup">
                    <Redirect to="/home" />
                  </Route>
                </>
              ) : (
                <>
                  <Route path="/" exact component={LoginSSOPage} />
                  <Route path="/login" exact component={LoginSSOPage} />
                  <Route
                    path="/forgotpassword"
                    exact
                    component={ForgotPasswordPage}
                  />
                  <Route
                    path="/reset_password"
                    exact
                    component={CreatePasswordPage}
                  />
                  <Route
                    path="/set_password"
                    exact
                    component={CreatePasswordPage}
                  />
                  <Route
                    path="/redirect/:token"
                    exact
                    component={redirectPage}
                  />
                </>
              )}
            </MaintenanceLayout>
          </Switch>
        </BrowserRouter>
      </ApolloProvider>
    </div>
  );
}

export default App;
