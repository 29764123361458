import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { RESET_PASSWORD_FROM_WEB } from "../../GraphQL/mutations";
import { Button, Container, Row } from "react-bootstrap";
import LoadingComponent from "../shared/loadingComponent";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgotPasswordComponent() {
  const [username, setUserName] = useState("");
  let errorsObj = { username: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [isLoading, setIsLoading] = useState(false);

  const [resetPassword] = useMutation(RESET_PASSWORD_FROM_WEB);

  const emailSubmit = (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (!username) {
      errorObj.username = "Email is required";
      error = true;
    }

    if (username !== "") {
      error = /$^|.+@.+..+/.test(username) ? false : true;

      if (error) errorObj.nusername = "Invalid Email";
    }

    setErrors(errorObj);

    if (!error) {
      setIsLoading(true);
      resetPassword({
        variables: {
          email: username,
          resetLink: process.env.REACT_APP_WEB_BASE_URL + "reset_password",
        },
      })
        .then(({ data }) => {
          toast.success("Reset password link sent to your mail");
          setTimeout(() => {
            window.location.href = "/";
          }, 3000);
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <Container fluid className="app-container">
            <Row className="justify-content-center align-items-center min-vh-80">
              <div className="Login-Logo">
                <img src="/images/HealthMov-Logo.png" alt="" />
              </div>

              <div className="Login-Btn-Cust-Form">
                <h2>Forgot Password</h2>
                <p>
                  To reset your password, please enter your email address and
                  we'll send you a link to follow.
                </p>
                <div className="Login-Form">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={username}
                      id="exampleFormControlInput1"
                      placeholder="email@healthmov.com"
                      onChange={(e) => setUserName(e.target.value)}
                      required
                    />
                    {errors.username && (
                      <div className="error-msg">{errors.username}</div>
                    )}
                    {errors.nusername && (
                      <div className="error-msg">{errors.nusername}</div>
                    )}
                  </div>
                </div>
                <Button variant="primary main" onClick={emailSubmit}>
                  Submit
                </Button>
              </div>
            </Row>
          </Container>
          <div className="Main-Footer mb-3 mt-2">
            <p>Copyright 2022 © HealthMov All rights reserved.</p>
          </div>
        </>
      )}
    </>
  );
}

export default ForgotPasswordComponent;
