import React, { useEffect, useState, useCallback } from "react";
import { Container, Button, Row, Col, Card } from "react-bootstrap";
import { ReactComponent as CreditCardIcon } from "bootstrap-icons/icons/credit-card.svg";
import { ReactComponent as PeopleIcon } from "bootstrap-icons/icons/people.svg";
import PieChart from "../components/shared/pieChartComponent";
import LoadingComponent from "../components/shared/loadingComponent";
import * as am5 from "@amcharts/amcharts5";
import { tr } from "../components/labels";
import { LOAD_COMPANY_STATS } from "../GraphQL/queries";
import { useLazyQuery } from "@apollo/client";
import DemographicsIcon from "../icons_analytics/Mask group.svg";
import constants from "../components/shared/constants";
import HorizontalBarChart from "../components/shared/horizontalBarChartComponent";
import { ReactComponent as DownloadIcon } from "bootstrap-icons/icons/download.svg";
import { handleMutlipleExport, handleSingleExport } from "../components/utils";
import { useSelector } from "react-redux";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };
  return date.toLocaleString("en-US", options);
};

function HomePage() {
  const selectedCompany = useSelector((state) => state.company.company);
  const [analytics, setAnalytics] = useState([]);
  const [serieSeats, setSerieSeats] = useState({});
  const [serieSigned, setSerieSigned] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [currentCompanyId, setCurrentCompanyId] = useState(null);

  const [loadAnalyticsQuery] = useLazyQuery(LOAD_COMPANY_STATS);

  const loadAnalyticsCallback = useCallback(() => {
    if (
      selectedCompany &&
      selectedCompany.id &&
      selectedCompany.id !== currentCompanyId
    ) {
      loadAnalyticsQuery({
        fetchPolicy: "no-cache",
        variables: { id: selectedCompany.id },
        onCompleted: (data) => {
          if (data) {
            const updatedStats = data.company.stats.map((stat) => {
              if (!stat.showZeros) {
                return {
                  ...stat,
                  series: stat.series.filter((serie) => serie.value !== 0),
                };
              }
              return stat;
            });

            const filteredStats = updatedStats.filter((stat) => {
              return stat.section.includes("Demographics");
            });

            setAnalytics(filteredStats);
          }
          setSerieSeats({
            title: "Users",
            data: [
              {
                value: Math.max(
                  selectedCompany.maxSeats - selectedCompany.nbInvitations,
                  0
                ),
                text: tr("availableSeatsInfoLabel"),
                color: am5.color("#585563"),
              },
              {
                value: selectedCompany.nbInvitations,
                text: tr("usedSeatsInfoLabel"),
                color: am5.color("#ceb992"),
              },
            ],
          });
          if (selectedCompany.plan === "CONTRACT") {
            setSerieSigned({
              title: "Users",
              data: [
                {
                  value:
                    selectedCompany.nbOffboardedInvitations,
                  text: tr("offboardedLabel"),
                  color: am5.color("#585563"),
                },
                {
                  value:
                    selectedCompany.nbInvitations -
                    selectedCompany.nbOffboardedInvitations - 
                    selectedCompany.nbSignedUpInvitations,
                  text: tr("nonSignedUpLabel"),
                  color: am5.color("#97d7dc"),
                },
                {
                  value:
                    selectedCompany.nbSignedUpInvitations -
                    selectedCompany.nbOnboardedInvitations,
                  text: tr("nonOboardedLabel"),
                  color: am5.color("#67b7dc"),
                },
                {
                  value: selectedCompany.nbOnboardedInvitations,
                  text: tr("onboardedLabel"),
                  color: am5.color("#67b7dc"),
                },
              ],
            });
          } else {
            setSerieSigned({
              title: "Invitations",
              data: [
                {
                  value: selectedCompany.nbOnboardedInvitations,
                  text: tr("onboardedLabel"),
                  color: am5.color("#97d7dc"),
                },
                {
                  value: selectedCompany.nbAcceptedInvitations,
                  text: tr("acceptedLabel"),
                  color: am5.color("#67b7dc"),
                },
                {
                  value: selectedCompany.nbPendingInvitations,
                  text: tr("pendingLabel"),
                  color: am5.color("#67b7dc"),
                },
                {
                  value: selectedCompany.nbSignedUpInvitations,
                  text: tr("signedUpLabel"),
                  color: am5.color("#67b7dc"),
                },
              ],
            });
          }
          setIsLoading(false);
        },
        onError: (error) => {
          console.error(error);
          setIsLoading(false);
        },
      });
    }
  }, [loadAnalyticsQuery, selectedCompany, currentCompanyId]);

  const isFakeInAnalytics = () => {
    return analytics.some((analytic) => analytic.isFake);
  };

  useEffect(() => {
    if (
      selectedCompany &&
      selectedCompany.id !== currentCompanyId
    ) {
      setCurrentCompanyId(selectedCompany.id);
      setIsLoading(true);
      loadAnalyticsCallback();
    }
  }, [
    selectedCompany,
    currentCompanyId,
    isLoading,
    loadAnalyticsCallback,
  ]);

  const onNavigate = (e) => {
    const dest = e.target.getAttribute("value");
    window.location.href = dest;
  };

  const format_val = (raw_value, nbDecimals, unit) => {
    if (raw_value === null) {
      return null;
    }
    const val = Number(raw_value.toFixed(nbDecimals || 0)).toLocaleString();
    let ret = val;
    if (unit) {
      ret = `${val} ${unit}`;
    }
    return ret;
  };

  const convert_to_serie = (data) => {
    const ret = {
      title: data.text,

      data: data.series
        .map((row, index) => {
          return {
            ...row,
            nbDecimals: data.nbDecimals,
            row_color: row.color,
            color: constants.COLORS[row.color],
          };
        })
        .sort((a, b) => a.value - b.value),
    };
    return ret;
  };

  const format_info = (data) => {
    if (data.dimensionText) {
      return `${format_val(data.nbMeasures, 0)} ${
        data.dimensionText
      } from ${format_val(data.nbUsers, 0)} users`;
    }
    return `${format_val(data.nbUsers, 0)} users`;
  };

  return isLoading || !selectedCompany ? (
    <LoadingComponent />
  ) : (
    <Container fluid="md" className="home">
      <div className="home-title">{tr("welcomeHealthMovLabel")}</div>
      {selectedCompany.plan === "TRIAL" ? (
        <>
          <div className="home-subtitle">
            {tr("congratsStartingJourneyLabel")}
          </div>
          <div className="home-subtitle-2">
            {tr("freeTrialEnjoyLabel")}{" "}
            <a href="mailto:support@healthmov.com">support@healthmov.com</a>{" "}
            {tr("questionsPreferredPlanLabel")}
          </div>
        </>
      ) : (
        <>
          <div className="home-subtitle">
            {tr("congratsJourneyHealthMovLabel")}
            <br />
            {tr("thankYouChoosingLabel")}
          </div>
          <div className="home-subtitle-2">
            {tr("letUsKnowLabel")}{" "}
            <a href="mailto:support@healthmov.com">support@healthmov.com</a>{" "}
            {tr("questionsFeedbackRequestsLabel")}
          </div>
        </>
      )}
      {serieSeats &&
      serieSeats.data &&
      serieSeats.data.length > 0 &&
      selectedCompany.plan !== "CONTRACT" ? (
        <Row xs={1} md={2} className="g-4 main-steps justify-content-center">
          <Col>
            <Card>
              <Card.Body>
                <PieChart
                  chartId="users-pie"
                  serie={serieSeats}
                  width={"100%"}
                  height={150}
                />
                {selectedCompany.plan === "TRIAL" ? (
                  <Button
                    variant="primary main"
                    value="/account-billing"
                    onClick={onNavigate}
                  >
                    <CreditCardIcon /> {tr("subscribeLabel")}
                  </Button>
                ) : (
                  <Button
                    variant="primary main"
                    value="/account-billing?buy=true"
                    onClick={onNavigate}
                  >
                    <CreditCardIcon /> {tr("buyMoreSeatsInfoLabel")}
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <PieChart
                  chartId="users-pie2"
                  serie={serieSigned}
                  width={"100%"}
                  height={150}
                />
                <Button
                  variant="primary main"
                  value="/users"
                  onClick={onNavigate}
                >
                  <PeopleIcon /> {tr("inviteLabel")}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row xs={1} md={2} className="g-4 main-steps justify-content-center">
          <Col>
            <Card className="min-height-150">
              <Card.Body>
                <Card.Title>{tr("totalUsersLabel")}</Card.Title>
                <div className="card-content">
                  <Card.Text className="very-big-number">
                    {selectedCompany.nbInvitations - selectedCompany.nbOffboardedInvitations}
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="min-height-150">
              <Card.Body>
                <PieChart
                  chartId="users-pie2"
                  serie={serieSigned}
                  width={"100%"}
                  height={150}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <img
          src={DemographicsIcon}
          className="icon-section-title"
          alt="section"
        />
        <h3 className="section-title">Demographics</h3>
        <button
          className="btn"
          onClick={() => handleMutlipleExport(analytics, "Demographics")}
          style={{ padding: "2px 5px" }}
        >
          <DownloadIcon />
        </button>
      </div>
      {isFakeInAnalytics() && (
        <Card className="is-fake-indicator">
          ⚠️ Sample data shown. Real data available when employee count exceeds
          25.
        </Card>
      )}
      <Row
        xs={1}
        md={2}
        className="g-4 justify-content-center align-items-start"
      >
        {analytics.map((data, idx) => (
          <Col md={6} key={idx}>
            <div>
              <Container className="bg-white">
                <Row
                  xs={1}
                  md={1}
                  className="g-4 main-steps justify-content-center align-items-start"
                >
                  <Col className="col-title">
                    <div className="measure-container">
                      <div className="measure-title">{data.text}</div>
                      <button
                        className="btn export-button"
                        onClick={() => handleSingleExport(data)}
                        style={{ padding: "2px 5px" }}
                      >
                        <DownloadIcon />
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="">
                      {!data.isReady || data.nbMeasures === 0 ? (
                        <></>
                      ) : (
                        <Card.Body>
                          <div className="measure-info">
                            <span className="strong">{format_info(data)}</span>
                          </div>
                        </Card.Body>
                      )}
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="min-height-150">
                      {!data.isReady ? (
                        <LoadingComponent />
                      ) : data.nbMeasures === 0 && !data.showZeros ? (
                        <Card.Body>
                          <img
                            alt={`no ${data.dimensionText}`}
                            className="nothing"
                            src="https://cdn.healthmov.com/app_icons/nothing.png"
                          />
                          <p>No {data.dimensionText} found !</p>
                        </Card.Body>
                      ) : (
                        <Card.Body>
                          {data.barChart ? (
                            <HorizontalBarChart
                              chartId={data.dimension}
                              serie={convert_to_serie(data)}
                              width={"100%"}
                              height={50 + data.series.length * 30}
                              legendWidth={"100%"}
                              legendHeight={"100%"}
                            />
                          ) : (
                            <PieChart
                              chartId={data.dimension}
                              serie={convert_to_serie(data)}
                              width={"100%"}
                              height={155}
                            />
                          )}
                        </Card.Body>
                      )}
                    </Card>
                  </Col>
                </Row>
                <span className="footer-card">
                  Data updated as on : {formatDate(data.updatedAt)}
                </span>
              </Container>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default HomePage;
