import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./pages/header";
import Footer from "./pages/footer";
import "./components/common.css";
import { headerVisible } from "./components/utils";
import { useQuery } from "@apollo/client";
import { LOAD_USER_INFO } from "./GraphQL/queries";
import "./App.css";
import { ReactComponent as Lock } from "bootstrap-icons/icons/file-earmark-lock.svg";
import { tr } from "./components/labels";
import { setProfile } from "./redux/profileslice";
import { useDispatch } from "react-redux";

function AppLayout({ children }) {
  const dispatch = useDispatch();

  const { loading, data } = useQuery(LOAD_USER_INFO);
  const userRoles = data?.me?.user?.userRoles;
  if (!loading) {
    const userRoles = data?.me?.user?.userRoles;
    if (!userRoles || userRoles.length === 0) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
  }
  const roleList = userRoles?.map((roleObj) => roleObj.role) || [];
  const canUsePortal =
    roleList.length && roleList.some((role) => role !== "EMPLOYEE");

  useEffect(() => {
    if (data) {
      const { me } = data;
      dispatch(setProfile(me));
      if (me && me.user && !me.user.canUsePortal) {
        if (window.location.pathname !== "/waiting") {
          window.location.href = "/waiting";
        }
      }
    }
  }, [data, dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const backLogin = () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  if (!canUsePortal && headerVisible()) {
    return (
      <div style={{ paddingTop: "20%" }}>
        <h1>{tr("accessLabel")}</h1>
        <h2>{tr("requestLabel")}</h2>
        <hr style={{ color: "transparent" }} />
        <Lock style={{ width: "128px", height: "128px", color: "#FF8C00" }} />
        <Link
          onClick={backLogin}
          style={{ display: "block", marginTop: "20px" }}
        >
          {tr("goToLoginLabel")}
        </Link>
      </div>
    );
  }

  return headerVisible() ? (
    <div className="cust-content">
      <Header/>
        <div className="Main-Dash-Content">{children}</div>
      <Footer />
    </div>
  ) : (
    <div className="cust-content">
      {children}
    </div>
  );
}

export default AppLayout;
