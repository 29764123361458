import React from "react";
import QuickSignupComponent from "../components/signup/quickSignupComponent";

function GitexSignupPage() {
  return <QuickSignupComponent 
    companyName="Gitex 2023"
    welcome="Get Free Access to your Health & Wellness journey"
    logoUrl="/images/HealthMov-Logo.png" />
}

export default GitexSignupPage;
