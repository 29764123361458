import React from "react";
import QRCode from "qrcode.react";
import { DOWNLOAD_APP_URL } from "../utils";

function OnboardComponent({ canUseApp, message }) {
  const downloadUrl = DOWNLOAD_APP_URL;

  return (
    <div style={{ marginTop: "20px" }}>
      {canUseApp ? (
        <>
          <p>
            Scan the QR code below to download the app
            <br />
            or click on this <a href={downloadUrl}>link</a> from your mobile.
          </p>
          <div>
            <QRCode value={downloadUrl} />
          </div>
        </>
      ) : (
        <>
          <p>{message}</p>
          <p>Stay tuned 😉</p>
        </>
      )}
    </div>
  );
}

export default OnboardComponent;
