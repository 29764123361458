import React from "react";

function FooterComponent() {
  return (
    <div className="Content-Footer">
      <p
        style={{
          textAlign: "left",
          paddingLeft: 20,
          color: "#707070",
          fontSize: "12px",
          fontFamily: "Work Sans",
          paddingTop: 7,
        }}
      >
        Copyright 2023 © HealthMov All rights reserved.
      </p>
    </div>
  );
}

export default FooterComponent;
