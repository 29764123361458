import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { ReactComponent as SignOutIcon } from "bootstrap-icons/icons/box-arrow-right.svg";
import { ReactComponent as HouseIcon } from "bootstrap-icons/icons/house.svg";
import { ReactComponent as PeopleIcon } from "bootstrap-icons/icons/people.svg";
import { ReactComponent as DependentIcon } from "bootstrap-icons/icons/people-fill.svg";
import { ReactComponent as PersonIcon } from "bootstrap-icons/icons/person.svg";
import { ReactComponent as InvoiceIcon } from "bootstrap-icons/icons/receipt.svg";
import { ReactComponent as GraphUpIcon } from "bootstrap-icons/icons/graph-up.svg";
import { ReactComponent as PieChartIcon } from "bootstrap-icons/icons/pie-chart.svg";
import { ReactComponent as BarChartIcon } from "bootstrap-icons/icons/bar-chart.svg";
import { ReactComponent as CreditCardIcon } from "bootstrap-icons/icons/credit-card.svg";
import { ReactComponent as BuildingIcon } from "bootstrap-icons/icons/building.svg";
import { ReactComponent as DownloadIcon } from "bootstrap-icons/icons/download.svg";
import { ReactComponent as ReferIcon } from "bootstrap-icons/icons/heart.svg";
import { tr } from "../labels";
import LanguageDropdown from "../dropdownLanguage";
import "./header.css";
import {
  LOAD_COMPANIES,
  LOAD_COMPANY_WITH_INVITATIONS,
} from "../../GraphQL/queries";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setCompany } from "../../redux/companyslice";

const NavLink = ({ href, isActive, children }) => (
  <Nav.Link href={href} style={isActive(href) ? { color: "#ff8c00" } : {}}>
    {children}
  </Nav.Link>
);

function HeaderComponent() {
  const { data, error } = useQuery(LOAD_COMPANIES);
  const selectedCompany = useSelector((state) => state.company.company);
  const dispatch = useDispatch();
  const profileObj = useSelector((state) => state.profile.profile);
  const { fullName, company, user } = profileObj || {};
  const { isAdmin, isUserManager } = user || {};
  const [companiesArr, setCompaniesArr] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState();

  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const [selectNewCompany] = useLazyQuery(LOAD_COMPANY_WITH_INVITATIONS, {
    fetchPolicy: "no-cache",
    variables: { id: selectedCompanyId },
    onCompleted: (data) => {
      dispatch(setCompany(data.company));
    },
  });

  useEffect(() => {
    if (selectedCompanyId) {
      selectNewCompany();
    }
  }, [selectedCompanyId, selectNewCompany]);

  const onSignOut = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  useEffect(() => {
    if (error) {
      onSignOut();
    }
    if (data) {
      const { ...companies } = data.companies || {};
      const { edges } = companies || {};
      var selectedCompanyInList = null;
      var currentCompany = null;
      var arr = [];
      for (const node of Object.values(edges)) {
        const comp = node.node;
        if (selectedCompany && selectedCompany.name === comp.name) {
          selectedCompanyInList = comp;
        }
        if (company && company.id === comp.id) {
          currentCompany = comp;
        }
        if (comp.name.indexOf("__bin__") < 0 && comp.name.indexOf("__") !== 0) {
          arr.push(comp);
        }
      }
      setCompaniesArr(arr);
      var co = selectedCompanyInList || currentCompany || arr[0];
      if (co) {
        setSelectedCompanyId(co.id);
      }
    }
  }, [data, error, company, selectedCompany]);

  const onSelectCompany = (e) => {
    e.preventDefault();
    const id = e.target.getAttribute("value");
    console.log(e.target.innerText);
    setSelectedCompanyId(id);
  };

  const shouldDisplayLanguageDropdown = !window.location.href.startsWith(
    "https://portal.healthmov.com"
  );

  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const parentCompanies = Array.from(
    new Set(
      companiesArr.map((company) => company.parent && company.parent.name)
    )
  ).filter(Boolean);

  const [parentCompany, setParentCompany] = useState("");

  const handleParentCompanyChange = (e) => {
    setParentCompany(e.target.value);
  };

  const filteredCompanies = companiesArr.filter((company) => {
    const matchesSearchTerm = company.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    let matchesParentCompany;
    if (parentCompany === "no_parent") {
      matchesParentCompany = !company.parent;
    } else {
      matchesParentCompany =
        !parentCompany ||
        (company.parent && company.parent.name === parentCompany);
    }

    return matchesSearchTerm && matchesParentCompany;
  });

  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/home">
          <img className="homeLogo" src="/images/HealthMov-Logo.png" alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink href="/home" isActive={isActive}>
              <HouseIcon /> {tr("homeLabel")}
            </NavLink>
            <NavLink href="/users" isActive={isActive}>
              <PeopleIcon /> {tr("usersLabel")}
            </NavLink>
            <NavLink href="/dependents" isActive={isActive}>
              <DependentIcon /> {tr("dependentsLabel")}
            </NavLink>
            <NavLink href="/health" isActive={isActive}>
              <PieChartIcon /> {tr("healthLabel")}
            </NavLink>
            <NavLink href="/engagement" isActive={isActive}>
              <GraphUpIcon /> {tr("statsLabel")}
            </NavLink>
            {companiesArr.length > 1 ? (
              <NavLink href="/reports-parent" isActive={isActive}>
                <BarChartIcon /> {tr("globalLabel")}
              </NavLink>
            ) : (
              <></>
            )}
          </Nav>
          <Nav>
            {companiesArr.length <= 1 ? (
              <></>
            ) : (
              <NavDropdown
                title={
                  <>
                    <BuildingIcon />
                    <span className="companyNameDropdown">
                      {" "}
                      {selectedCompany && selectedCompany.name}
                    </span>
                  </>
                }
                id="basic-nav-dropdown"
                className="fixed-width-dropdown"
              >
                <NavDropdown.Item>
                  <input
                    className="dropdown-search"
                    type="text"
                    placeholder="Filter company"
                    value={searchTerm}
                    onChange={handleInputChange}
                    onClick={(e) => e.stopPropagation()}
                  />
                </NavDropdown.Item>

                {isAdmin && (
                  <NavDropdown.Item>
                    <select
                      onChange={handleParentCompanyChange}
                      className="dropdown-search"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <option value="">All companies</option>
                      <option value="no_parent">No parent</option>
                      {parentCompanies.map((parentCompany, idx) => (
                        <option key={idx} value={parentCompany}>
                          Parent: {parentCompany}
                        </option>
                      ))}
                    </select>
                  </NavDropdown.Item>
                )}

                {filteredCompanies.map((company, idx) => (
                  <NavDropdown.Item
                    key={idx}
                    onClick={onSelectCompany}
                    value={company.id}
                  >
                    {company.name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            )}
            <Nav.Link href="/download" className="Link-custom">
              <div className="download-button-frame">
                <DownloadIcon className="download-button" />
              </div>
            </Nav.Link>
            {shouldDisplayLanguageDropdown && (
              <LanguageDropdown className="Link-custom" log={false} />
            )}
            <NavDropdown
              title={
                <span>
                  <PersonIcon className="me-1" />
                </span>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Header>
                {fullName}
                <br />
                <i>
                  {isAdmin
                    ? "Administrator"
                    : isUserManager
                    ? "Account Manager"
                    : ""}
                </i>
              </NavDropdown.Header>
              <NavDropdown.Item href="/profile">
                <PersonIcon /> {tr("profileLabel")}
              </NavDropdown.Item>
              {(isAdmin === true || selectedCompany?.name === "Hollard") && (
                <>
                  <Dropdown.Divider />
                  <NavDropdown.Item href="/invoices">
                    <InvoiceIcon /> Invoice
                  </NavDropdown.Item>
                </>
              )}
              {selectedCompany && selectedCompany.plan !== "CONTRACT" ? (
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item href="/account-billing">
                    <CreditCardIcon /> {tr("billingLabel")}
                  </Dropdown.Item>
                </>
              ) : (
                <div></div>
              )}
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={onSignOut}>
                <SignOutIcon /> {tr("signOutLabel")}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default HeaderComponent;
