import React, { useMemo, useState } from 'react'
import { Container, Button } from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { HOLLARD_INVOICE_FOR_PORTAL } from '../../GraphQL/mutations'
import { tr } from '../labels'
import FilterableDataTable from '../common/filterableDataTable'
import { useSelector } from 'react-redux'
import LoadingComponent from '../shared/loadingComponent'
import { toast } from "react-toastify";

function InvoiceComponent() {
  const profileObj = useSelector((state) => state.profile.profile)
  const { user } = profileObj || {}
  const { isAdmin } = user || {}

  const [selectedMonth, setSelectedMonth] = useState('')
  const [hollardInvoiceForPortal] = useMutation(HOLLARD_INVOICE_FOR_PORTAL)
  const [showTable, setShowTable] = useState(false)
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)

  const monthOptions = useMemo(() => {
    const startYear = 2023
    const startMonth = 0
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    const currentDate = new Date().getDate()

    let months = []

    months.push(
      <option key="default" value="" disabled selected>
        {tr('selectMonthLabel')}
      </option>
    )

    for (let year = startYear; year <= currentYear; year++) {
      let month = year === startYear ? startMonth : 0
      let maxMonth = year === currentYear ? currentMonth : 11

      if (!isAdmin && currentDate < 15 && year === currentYear) {
        maxMonth -= 1
      }

      for (; month <= maxMonth; month++) {
        let monthName = new Date(year, month).toLocaleString('default', {
          month: 'long',
        })
        let formattedMonth = String(month + 1).padStart(2, '0')
        months.push(
          <option
            key={`${monthName}-${year}`}
            value={`${year}-${formattedMonth}`}
          >
            {`${monthName} ${year}`}
          </option>
        )
      }
    }

    return months
  }, [isAdmin])

  const handleChange = (e) => {
    setSelectedMonth(e.target.value)
  }

  const runMutation = (jobId) => {
    document.body.style.cursor = 'wait'
    setShowTable(false)
    hollardInvoiceForPortal({
      fetchPolicy: 'no-cache',
      variables: { month: selectedMonth, jobId: jobId },
      onCompleted: (data) => {
        if (data.hollardInvoiceForPortal.jobId) {
          setLoading(true)
          console.log('Set Job Id = ', data.hollardInvoiceForPortal.jobId)
          setTimeout(() => runMutation(data.hollardInvoiceForPortal.jobId), 5000)
        } else {
          document.body.style.cursor = 'default'
          setLoading(false)
          const parsedResponse = JSON.parse(
            data.hollardInvoiceForPortal.success
          )
          //   console.log(parsedResponse);
          setShowTable(true)
          setTableData(parsedResponse)
        }
      },
      onError: (err) => {
        document.body.style.cursor = 'default'
        toast.error(err.message)
      },
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    runMutation(null)
  }

  return (
    <Container>
      <h2 style={{ textAlign: 'left', marginBottom: '20px' }}>
        {tr('invoiceLabel')}
      </h2>
      <select onChange={handleChange}>{monthOptions}</select>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Button
          style={{ marginTop: '20px' }}
          variant="primary main"
          onClick={onSubmit}
        >
          {tr('submitLabel')}
        </Button>
      )}
      {showTable && <FilterableDataTable data={tableData} />}
    </Container>
  )
}

export default InvoiceComponent
