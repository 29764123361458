import React from "react";
import { Dropdown } from "react-bootstrap";
import frFlag from "./flagsCountry/flag_fr.png";
import enFlag from "./flagsCountry/flag_en.png";
import arFlag from "./flagsCountry/flag_ar.png";
import ptFlag from "./flagsCountry/flag_pt.png";
// import TranslateStringsExample, { getCurrentLanguage } from "./Translation";
import { getCurrentLanguage } from "./Translation";
import useUpdateTranslations from "./Translation";

const LANGUAGES = [
  { value: "en", label: "English", flag: enFlag },
  { value: "fr", label: "Français", flag: frFlag },
  { value: "ar", label: "العربية", flag: arFlag },
  { value: "pt", label: "Português", flag: ptFlag },
];

const LanguageDropdown = ({ log }) => {
  const updateTranslations = useUpdateTranslations(() => {
    console.log("Language changed to:");
    window.location.reload();
  });

  const handleChange = (selectedOption) => {
    updateTranslations(selectedOption);
  };

  const currentLanguageObj = LANGUAGES.find(
    (language) => language.value === getCurrentLanguage()
  );

  return (
    <>
      <div className="select-language">
        <Dropdown onSelect={handleChange}>
          <Dropdown.Toggle
            variant="outline-secondary"
            id="language-dropdown"
            style={{
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
              color: "#6c757d",
            }}
          >
            <img
              src={currentLanguageObj.flag}
              alt={currentLanguageObj.label}
              style={{
                marginRight: "8px",
                width: "20px",
                height: "20px",
              }}
            />
            {log && <span>{currentLanguageObj.label}</span>}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {LANGUAGES.map((language) => (
              <Dropdown.Item key={language.value} eventKey={language.value}>
                <img
                  src={language.flag}
                  alt={language.label}
                  style={{ marginRight: "8px", width: "16px", height: "16px" }}
                />
                {language.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default LanguageDropdown;
