import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Container, Row } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { useMutation } from "@apollo/client";
import { SIGNUP } from "../../GraphQL/mutations";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { DOWNLOAD_APP_URL, isValidEmail } from "../utils";
import { tr } from "../labels";
import LanguageDropdown from "../dropdownLanguage";
import DownloadComponent from "../download/downloadComponent";
import { getCurrentLanguage } from "../Translation";
import useUpdateTranslations from "../Translation";

const SignupComponent = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const referalCode = params.referal_code || params.referral_code;
  const [formData, setFormData] = useState({
    companyName: "",
    website: "",
    companySize: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    referalCode: referalCode || "",
    email: "",
    resetLink: process.env.REACT_APP_WEB_BASE_URL + "set_password",
  });
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [signup] = useMutation(SIGNUP, { fetchPolicy: "no-cache" });

  let errorsObj = { password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [isSigned, setIsSigned] = useState(false);
  const [captcha, setCaptcha] = useState(null);

  const onRunMutation = (method, methodName, variables) => {
    document.body.style.cursor = "wait";
    method({
      variables: variables || {},
    })
      .then(({ data }) => {
        document.body.style.cursor = "default";
        if (data[methodName].error) {
          toast.error(data[methodName].error);
        } else {
          setIsSigned(true);
          localStorage.removeItem("token");
          toast.success(data[methodName].success);
          window.lintrk("track", { conversion_id: 13983921 });
        }
      })
      .catch((err) => {
        document.body.style.cursor = "default";
        if (methodName in err) {
          toast.error(err[methodName].error);
        } else {
          toast.error(err.message);
        }
      });
  };
  const onSignup = () => {
    onRunMutation(signup, "signup", formData);
  };
  const setPhoneNumber = (value) => {
    setFormData({ ...formData, phoneNumber: value });
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    let error = false;
    const errorObj = { ...errorsObj };

    if (!termsAccepted) {
      errorObj.termsAccepted = "You need to accept the terms and conditions";
      error = true;
    }

    if (!formData.companyName) {
      errorObj.companyName = "Company name is required ";
      error = true;
    }
    if (!formData.phoneNumber) {
      errorObj.phoneNumber = "Phone number is required ";
      error = true;
    } else if (!isValidPhoneNumber(formData.phoneNumber)) {
      errorObj.phoneNumber = "Phone number is not valid ";
      error = true;
    }

    if (!formData.email) {
      errorObj.email = "Email is required ";
      error = true;
    }
    if (!formData.firstName) {
      errorObj.firstName = "First name is required ";
      error = true;
    }
    if (!formData.lastName) {
      errorObj.lastName = "Last name is required ";
      error = true;
    }
    if (!isValidEmail(formData.email)) {
      errorObj.email = "Email is not valid ";
      error = true;
    }
    if (!captcha) {
      errorObj.captcha = "Please complete the captcha to continue";
      error = true;
    }
    if (!formData.website) {
      errorObj.website = "Website is required ";
      error = true;
    } else {
      if (formData.website.indexOf("http") < 0) {
        formData.website = "https://" + formData.website;
      }
      try {
        new URL(formData.website);
      } catch {
        errorObj.website = "Website URL should start with http:// or https://";
        error = true;
      }
    }
    // const domain = formData.email.split("@")[1]
    // if (formData.website.indexOf(domain) < 0) {
    //     errorObj.email = "Please use your professional email to match your company's website";
    //     error = true;
    // }
    if (!formData.companySize) {
      errorObj.companySize = "Company size is required ";
      error = true;
    }
    setErrors(errorObj);

    if (!error) {
      onSignup();
    }
  };
  const handleTermsAccepted = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const shouldDisplayLanguageDropdown = !window.location.href.startsWith(
    "https://portal.healthmov.com"
  );

  const link = DOWNLOAD_APP_URL;

  const [isRedirecting, setIsRedirecting] = useState(false);

  const updateTranslations = useUpdateTranslations();

  useEffect(() => {
    if (!isSigned) return;

    const isMobile = /iPhone|iPad|iPod|Android/i.test(
      window.navigator.userAgent
    );

    if (isMobile) {
      setIsRedirecting(true);
      setTimeout(() => {
        window.location.href = link;
      }, 3000);
    }

    updateTranslations(getCurrentLanguage());
  }, [isSigned, updateTranslations]);

  return (
    <>
      <Container fluid className="app-container">
        <Row className="justify-content-center align-items-center min-vh-80">
          <div className="Login-Logo">
            <img src="/images/HealthMov-Logo.png" alt="" />
          </div>
          <div className="Login-Btn-Cust-Form">
            {isSigned ? (
              <>
                {isRedirecting ? (
                  <>
                    <h2>{tr("signUpCompleteLabel")}</h2>
                    <p>{tr("redirectLabel")}</p>
                    <button
                      className="btn-main"
                      onClick={() => (window.location.href = link)}
                    >
                      {tr("downloadLabel")}
                    </button>
                  </>
                ) : (
                  <DownloadComponent message="Sign-up completed!" />
                )}
              </>
            ) : (
              <>
                <h2>{tr("startTrialLabel")}</h2>

                <form className="Login-Form">
                  <div>
                    <label className="form-label" htmlFor="companyName">
                      {tr("companyNameLabel")}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="companyName"
                      name="companyName"
                      required
                      value={formData.companyName}
                      onChange={handleChange}
                    />
                    {errors.companyName && (
                      <div className="error-msg">{errors.companyName}</div>
                    )}
                  </div>
                  <div>
                    <label className="form-label" htmlFor="website">
                      {tr("companyWebsiteLabel")}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="website"
                      name="website"
                      placeholder="https://example.com"
                      required
                      value={formData.website}
                      onChange={handleChange}
                    />
                    {errors.website && (
                      <div className="error-msg">{errors.website}</div>
                    )}
                  </div>
                  <div>
                    <label className="form-label" htmlFor="companySize">
                      {tr("companySizeLabel")}
                    </label>
                    <select
                      className="form-control"
                      id="companySize"
                      name="companySize"
                      required
                      value={formData.companySize}
                      onChange={handleChange}
                    >
                      <option value="" disabled hidden>
                        {tr("selectLabel")}
                      </option>
                      <option value="1-10">1-10</option>
                      <option value="11-50">11-50</option>
                      <option value="51-100">51-100</option>
                      <option value="101-500">101-500</option>
                      <option value="501-1000">501-1000</option>
                      <option value="1001-5000">1001-5000</option>
                      <option value="5001+">5001+</option>
                    </select>
                    {errors.companySize && (
                      <div className="error-msg">{errors.companySize}</div>
                    )}
                  </div>
                  <div>
                    <label className="form-label" htmlFor="email">
                      {tr("yourNameLabel")}
                    </label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          className="form-control"
                          type="text"
                          id="firstName"
                          name="firstName"
                          placeholder={tr("firstNameLabel")}
                          required
                          value={formData.firstName}
                          onChange={handleChange}
                        />
                        {errors.firstName && (
                          <div className="error-msg">{errors.firstName}</div>
                        )}
                      </div>
                      <div className="col-6">
                        <input
                          className="form-control"
                          type="text"
                          id="lastName"
                          name="lastName"
                          placeholder={tr("lastNameLabel")}
                          required
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                        {errors.lastName && (
                          <div className="error-msg">{errors.lastName}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="form-label" htmlFor="email">
                      {tr("yourEmailLabel")}
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      id="email"
                      name="email"
                      required
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <div className="error-msg">{errors.email}</div>
                    )}
                  </div>
                  <div>
                    <label className="form-label">
                      {tr("phoneNumberLabel")}
                    </label>
                    <PhoneInput
                      className="form-control"
                      placeholder={tr("phoneNumberLabel")}
                      value={formData.phoneNumber || ""}
                      id="phoneNumber"
                      required
                      withCountryCallingCode
                      defaultCountry="AE"
                      onChange={setPhoneNumber}
                    />
                    {errors.phoneNumber && (
                      <div className="error-msg">{errors.phoneNumber}</div>
                    )}
                  </div>
                  {/* <div>
                    <label className="form-label" htmlFor="referalCode">
                      {tr("referralCodeLabel")}
                    </label>
                    <input
                      className="form-control"
                      type="referalCode"
                      id="referalCode"
                      name="referalCode"
                      required
                      value={formData.referalCode}
                      onChange={handleChange}
                    />
                  </div> */}
                  <div className="t_and_c">
                    <label className="form-label" htmlFor="terms-checkbox">
                      <input
                        type="checkbox"
                        id="terms-checkbox"
                        name="termsAccepted"
                        checked={termsAccepted}
                        onChange={handleTermsAccepted}
                      />
                      {tr("agreeLabel")}{" "}
                      <a
                        href="https://www.healthmov.com/terms-and-conditions/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {tr("termsLabel")}
                      </a>
                      .
                    </label>
                    {errors.termsAccepted && (
                      <div className="error-msg">{errors.termsAccepted}</div>
                    )}
                  </div>
                  <div className="row mt15 justify-content-center">
                    <ReCAPTCHA
                      sitekey="6LezS5AjAAAAAB6s6cmCpVyBfxtYakMOiJm2pnrq"
                      onChange={setCaptcha}
                      className="captcha"
                    />
                    {errors.captcha && (
                      <div className="error-msg">{errors.captcha}</div>
                    )}
                  </div>

                  <div className="go-to-login">
                    <span>
                      {tr("alreadysignedLabel")}{" "}
                      <Link to="login">{tr("goToLoginLabel")}</Link>
                    </span>
                  </div>

                  <button className="btn-main" onClick={handleSubmit}>
                    {tr("signUpLabel")}
                  </button>
                </form>
              </>
            )}
          </div>
          <div style={{ textAlign: "right" }}>
            {shouldDisplayLanguageDropdown && <LanguageDropdown log={true} />}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SignupComponent;
