import React, { useEffect, useState } from "react";
import "./profile.css";
import { useMutation } from "@apollo/client";
import {
  UPDATE_PASSWORD,
  UPLOAD_PICTURE,
  UPDATE_PROFILE_NAMES,
} from "../../GraphQL/mutations";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingComponent from "../shared/loadingComponent";
import { tr } from "../labels";
import { Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setProfileUrl } from "../../redux/profileslice";

toast.configure();

function ProfileComponent() {
  const [updatePassword] = useMutation(UPDATE_PASSWORD);

  const [uploadPicture] = useMutation(UPLOAD_PICTURE);

  const [passwordObj, setPasswordObj] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [passwordErrors, setPasswordErrors] = useState(false);

  const profileObj = useSelector((state) => state.profile.profile);
  console.log("profileObj:", profileObj);
  const firstName = profileObj?.firstName;
  const lastName = profileObj?.lastName;
  const pictureUrl = profileObj?.pictureUrl;
  const company = profileObj?.company;
  const name = company?.name;
  const dispatch = useDispatch();
  const updateImageUrl = (url) => dispatch(setProfileUrl(url));
  console.log("company:", company);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {}, []);
  const onPasswordChange = (e) => {
    e.preventDefault();

    if (
      passwordObj.currentPassword === "" ||
      passwordObj.newPassword === "" ||
      passwordObj.confirmPassword === ""
    ) {
      setPasswordErrors("Please fill all fields");
      return;
    }
    if (passwordObj.newPassword !== passwordObj.confirmPassword) {
      setPasswordErrors("New password and Confirm password not match");
      return;
    }
    setPasswordErrors(false);
    updatePassword({
      variables: {
        currentPassword: passwordObj.currentPassword,
        newPassword: passwordObj.confirmPassword,
      },
    })
      .then(({ data }) => {
        toast.success("Password successfully changed!");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleImageChange = async (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const base64 = await convertBase64(file);
      const str = base64.replace("data:", "").replace(/^.+,/, "");
      imageUpload(e, str);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const imageUpload = async (e, string) => {
    if (e.target.files.length) {
      setIsLoading(true);
      uploadPicture({
        variables: {
          file: string,
          name: e.target.files[0].name,
        },
      })
        .then(({ data }) => {
          const { uploadPicture } = data || {};
          const { url } = uploadPicture || {};
          updateImageUrl(url);
          toast.success("Profile image updated successfully!");
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [updateProfileNames] = useMutation(UPDATE_PROFILE_NAMES);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleChangeName = (firstName, lastName) => {
    console.log("First Name:", firstName);
    console.log("Last Name:", lastName);
    updateProfileNames({
      variables: {
        firstName: firstName,
        lastName: lastName,
      },
    }).then(({ data }) => {
      toast.success("Profile names updated successfully!");
      window.location.reload();
    });
  };

  return (
    <>
      <div class="Header">
        <h2 style={{ textAlign: "left" }}>{tr("settingsLabel")}</h2>
      </div>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <div class="row Main-Setting-Box">
            <div class="Priofile-Box">
              <div class="Profile-Content">
                <div class="Profile-Img">
                  <img
                    src={
                      pictureUrl ? pictureUrl : "/images/Bg-Image-Profile.png"
                    }
                    alt=""
                  />
                  <span>
                    <img
                      src="/images/Edit.png"
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={handleShow}
                    />
                  </span>
                </div>
                <div class="Profile-Content">
                  <h4>
                    {firstName} {lastName && lastName[0] + "."}
                  </h4>
                  <p>{name}</p>
                </div>
              </div>
            </div>
            <div class="Setiings-Information-Box">
              <div class="Information-Header">
                <h2 style={{ textAlign: "left" }}>{tr("informationsLabel")}</h2>
              </div>
              <div class="Right-Setting-Content-Cust">
                <div class="Change-Pawd-Content">
                  <h3 style={{ textAlign: "left" }}>
                    {tr("changePasswordLabel")}
                  </h3>
                  <div class="Chang-Paswd-Cuts">
                    <form>
                      <div class="form-group">
                        <label
                          htmlFor="exampleInputPassword1"
                          class="Setting-Pwd-Label"
                        >
                          {tr("oldPasswordLabel")}
                        </label>
                        <input
                          type="password"
                          class="form-control Input-Password-design"
                          id="exampleInputPassword1"
                          placeholder="************"
                          onChange={(e) => {
                            setPasswordObj({
                              ...passwordObj,
                              currentPassword: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div class="form-group">
                        <label
                          htmlFor="exampleInputPassword2"
                          class="Setting-Pwd-Label"
                        >
                          {tr("newPasswordLabel")}
                        </label>
                        <input
                          type="password"
                          class="form-control Input-Password-design"
                          id="exampleInputPassword1"
                          placeholder="************"
                          onChange={(e) => {
                            setPasswordObj({
                              ...passwordObj,
                              newPassword: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div class="form-group">
                        <label
                          htmlFor="exampleInputPassword3"
                          class="Setting-Pwd-Label"
                        >
                          {tr("confirmPasswordLabel")}
                        </label>
                        <input
                          type="password"
                          class="form-control Input-Password-design"
                          id="exampleInputPassword1"
                          placeholder="************"
                          onChange={(e) => {
                            setPasswordObj({
                              ...passwordObj,
                              confirmPassword: e.target.value,
                            });
                          }}
                        />
                        {passwordErrors && (
                          <div className="error-msg">{passwordErrors}</div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                <div class="Info-Radio-Btn-Cust">
                  <h3>{tr("autoReportsEmailLabel")}</h3>
                  <div class="Setting-Radio-btn-Cust">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        class="form-check-label Stng-radio-Lbl"
                        for="flexRadioDefault1"
                      >
                        {tr("oncePerWeekLabel")}
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked
                      />
                      <label
                        class="form-check-label Stng-radio-Lbl"
                        for="flexRadioDefault2"
                      >
                        {tr("oncePerMonthLabel")}
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked
                      />
                      <label
                        class="form-check-label Stng-radio-Lbl"
                        for="flexRadioDefault3"
                      >
                        {tr("noneLabel")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Centr-Btn">
                <button
                  type="submit"
                  class="btn btn-main"
                  onClick={onPasswordChange}
                >
                  {tr("confirmLabel")}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{tr("editProfileLabel")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div class="Profile-Img">
              {/* <img src="/images/Background_image.png" alt=''/> */}
              <img
                src={pictureUrl ? pictureUrl : "/images/Bg-Image-Profile.png"}
                alt=""
              />
              <span>
                <label htmlFor="upload-button">
                  <img
                    type="file"
                    src="/images/Edit.png"
                    alt=""
                    style={{ cursor: "pointer" }}
                  />
                </label>
              </span>
              <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleImageChange}
                accept=".jpeg, .jpg, .png"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="firstNameInput">{tr("firstNameLabel")}</label>
            <input
              type="text"
              className="form-control"
              id="firstNameInput"
              defaultValue={firstName}
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastNameInput">{tr("lastNameLabel")}</label>
            <input
              type="text"
              className="form-control"
              id="lastNameInput"
              defaultValue={lastName}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{
              backgroundColor: "#FF9C45",
              color: "#FFFFFF",
              border: "none",
            }}
            onClick={() => {
              handleChangeName(
                document.getElementById("firstNameInput").value,
                document.getElementById("lastNameInput").value
              );
              handleClose();
            }}
          >
            {tr("confirmLabel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProfileComponent;
