import React from "react";
import QuickSignupComponent from "../components/signup/quickSignupComponent";

function HollardWellnessDaySignupPage() {
  return <>
    <QuickSignupComponent 
      companyName="Hollard Mozambique Wellness" 
      welcome="Welcome to HealthMov"
      logoUrl="/images/wellness-logo.png" />
    <div style={{ 
            position: "fixed",
            backgroundImage: "url(/images/wellness-bg.png)",
            backgroundSize: "cover",
            backgroundRepeat: "repeat-x",
            top: 0,
            height: "50px",
            width: "100%",
            }}>
        </div>
  </>
}

export default HollardWellnessDaySignupPage;
