import * as am5 from "@amcharts/amcharts5";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  FILTER_TYPE: {
    GENDER: "gender",
    AGE: "age",
    TOTAL: "total",
    MALE: "male",
    FEMALE: "female",
    OTHER: "other",
    MINUS20: "20-",
    BETWEEN2130: "21-30",
    BETWEEN3140: "31-40",
    BETWEEN4150: "41-50",
    BETWEEN5160: "51-60",
    PLUS60: "60+",
  },

  HOME_CHART_TYPES: {
    ACTIVE_EMPLOYEES: "Active Employees",
    ENGAGED_IN_CHALLENGES: "Engaged in Challenges",
    AVERAGE_HEALTHSCORE: "Average HealthScore",
    ESTIMATED_ROH: "Estimated ROH",
    AGE_CLASS: "Age class",
    GENDER: "Gender",
  },

  COLORS: {
    male: am5.color("#585563"),
    female: am5.color("#ceb992"),
    other: am5.color("#471323"),
    total: am5.color("#44F"),
    active: am5.color("#fd902d"),
    inactive: am5.color("#fdb04d"),
    gender: am5.color("#F00"),
    engaged: am5.color("#67b7dc"),
    unengaged: am5.color("#97d7dc"),
    very_low: am5.color("#318d16"),
    high: am5.color("#fc2c2c"),
    low: am5.color("#21d626"),
    very_high: am5.color("#be0712"),
    average: am5.color("#fffd39"),
    "20-": am5.color("#ebf38b"),
    "21-30": am5.color("#f4e87c"),
    "31-40": am5.color("#cbbf7a"),
    "41-50": am5.color("#9f956c"),
    "51-60": am5.color("#584d3d"),
    "60+": am5.color("#000000"),
    dark_green: am5.color("#9DCFB4"),
    green: am5.color("#B5E4CA"),
    yellow: am5.color("#FFDD96"),
    light_red: am5.color("#E89898"),
    red: am5.color("#EA4867"),
  },
};
