import { gql } from "@apollo/client";

export const LOGIN_USER_MUTATION = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      ok
      accessToken
      accessTokenExpirationDate
      refreshToken
      refreshTokenExpirationDate
      user {
        email
      }
    }
  }
`;

export const GOOGLE_LOGIN_MUTATION = gql`
  mutation loginSSO($token: String!) {
    loginSSO(token: $token) {
      ok
      accessToken
      accessTokenExpirationDate
      refreshToken
      refreshTokenExpirationDate
      user {
        email
      }
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($currentPassword: String!, $newPassword: String!) {
    updatePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      ok
    }
  }
`;

export const SIGNUP = gql`
  mutation signup(
    $companyName: String!
    $website: String!
    $companySize: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $resetLink: String!
    $phoneNumber: String!
    $referalCode: String
  ) {
    signup(
      companyName: $companyName
      website: $website
      companySize: $companySize
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      referalCode: $referalCode
      resetLink: $resetLink
    ) {
      ok
      success
      error
      resetLink
    }
  }
`;

export const QUICK_SIGNUP = gql`
  mutation quickSignup(
    $companyName: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
  ) {
    quickSignup(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      companyName: $companyName
    ) {
      ok
      success
      error
    }
  }
`;

export const RETRIEVE_INFO_FROM_EMAIL = gql`
  mutation retrieveInfoFromEmail(
    $email: String!
  ) {
    retrieveInfoFromEmail(
      email: $email
    ) {
      ok
      success
      error
      firstName
      lastName
      phoneNumber
    }
  }
`;

export const RESET_PASSWORD_FROM_WEB = gql`
  mutation resetPasswordFromWeb($email: String!, $resetLink: String!) {
    resetPasswordFromWeb(email: $email, resetLink: $resetLink) {
      ok
      resetLink
    }
  }
`;

export const CREATE_PASSWORD = gql`
  mutation createPassword($password: String!) {
    createPassword(password: $password) {
      ok
      user {
        email
      }
    }
  }
`;

export const UPLOAD_PICTURE = gql`
  mutation uploadPicture($file: Upload!, $name: String!) {
    uploadPicture(file: $file, name: $name) {
      ok
      url
    }
  }
`;

export const INVITE_USERS = gql`
  mutation inviteUsers($emails: [String]!, $link: String!) {
    inviteUsers(emails: $emails, link: $link) {
      ok
      success
      error
    }
  }
`;

export const DELETE_INVITATIONS = gql`
  mutation deleteInvitations($emails: [String]!) {
    deleteInvitations(emails: $emails) {
      ok
      success
      error
    }
  }
`;

export const ACTIVATE_USER = gql`
  mutation activateUser($email: String!, $activate: Boolean!) {
    activateUser(email: $email, activate: $activate) {
      ok
      success
      error
    }
  }
`;

export const CLOSE_ACCOUNT = gql`
  mutation closeAccount($companyId: ID!) {
    closeAccount(companyId: $companyId) {
      ok
      success
      error
    }
  }
`;

export const REDIRECT_TO_FIRST_CHECKOUT = gql`
  mutation redirectToFirstCheckout(
    $companyId: ID!
    $plan: String!
    $seats: Int!
    $yearly: Boolean!
  ) {
    redirectToFirstCheckout(
      companyId: $companyId
      plan: $plan
      seats: $seats
      yearly: $yearly
    ) {
      url
    }
  }
`;

export const ADD_SEATS = gql`
  mutation addSeats($companyId: ID!, $seats: Int!) {
    addSeats(companyId: $companyId, seats: $seats) {
      ok
      success
      error
      company {
        id
        uuid
        name
        plan
        nbInvitations
        maxSeats
        requestedPlan
        onboardedAt
        subscriptionStatus
        subscriptionTotalPriceUsd
        subscriptionPerSeatPriceUsd
        subscriptionStartedAt
        subscriptionRenewAt
        subscriptionInterval
      }
    }
  }
`;

export const REDIRECT_TO_CUSTOMER_PORTAL = gql`
  mutation redirectToCustomerPortal($companyId: ID!) {
    redirectToCustomerPortal(companyId: $companyId) {
      url
    }
  }
`;

export const ACCEPT_INVITATION = gql`
  mutation acceptInvitation(
    $uuid: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $email: String!
  ) {
    acceptInvitation(
      uuid: $uuid
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      email: $email
    ) {
      ok
      error
      success
      uuid
    }
  }
`;

// TODO : to remove
export const UPDATE_INVITATION = gql`
  mutation updateInvitation(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
  ) {
    updateInvitation(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
    ) {
      ok
      success
      error
    }
  }
`;

export const TRANSLATE_STRINGS = gql`
  mutation translateStrings($languages: [String!]!, $strings: [String!]!) {
    translateStrings(languages: $languages, strings: $strings) {
      result {
        language
        result {
          key
          value
        }
      }
    }
  }
`;

export const UPDATE_PROFILE_NAMES = gql`
  mutation updateProfile($firstName: String!, $lastName: String!) {
    updateProfile(firstName: $firstName, lastName: $lastName) {
      ok
    }
  }
`;

export const HOLLARD_INVOICE_FOR_PORTAL = gql`
  mutation hollardInvoiceForPortal($month: Date!, $jobId: Int) {
    hollardInvoiceForPortal(month: $month, jobId: $jobId) {
      ok
      error
      success
      jobId
    }
  }
`;
