import React, { useEffect, useState } from "react";
import LoadingComponent from "../shared/loadingComponent";
import useQueryParam from "../../hooks/useQueryParam";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMutation } from "@apollo/client";
import { CREATE_PASSWORD, LOGIN_USER_MUTATION } from "../../GraphQL/mutations";
import { decodePassword, hashPassword } from "../utils";

function CreatePasswordComponent() {
  const params = useQueryParam();
  const [resetToken, setResetToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [loginUser] = useMutation(LOGIN_USER_MUTATION);

  const [isLoading, setIsLoading] = useState(false);
  const gotoLogin = () => {
    loginUser({
      variables: {
        username: localStorage.getItem("username"),
        password: decodePassword(localStorage.getItem("log")),
      },
    })
      .then(({ data }) => {
        if (
          data !== undefined &&
          (data?.login?.accessToken != null || data?.login?.accessToken !== "")
        ) {
          localStorage.setItem("token", data.login.accessToken);
          window.location.href = "/home";
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  let errorsObj = { password: "" };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    if (params.get("token")) {
      setResetToken(params.get("token"));
    } else {
      window.location.href = "/";
    }
  }, [params]);

  const [createPassword] = useMutation(CREATE_PASSWORD, {
    context: {
      headers: {
        Authorization: `Bearer ${resetToken}`,
        "Content-Type": "application/json",
      },
    },
    skip: !resetToken,
  });

  const passwordSubmit = (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (!password) {
      errorObj.password = "Password  is required ";
      error = true;
    }

    if (!confirmPassword) {
      errorObj.confirmPassword = "Confirm Password  is required ";
      error = true;
    }

    if (confirmPassword && confirmPassword.length < 8) {
      errorObj.confirmPassword = "Password must be greater than 8 characters";
      error = true;
    }

    if (password !== confirmPassword) {
      errorObj.confirmPassword = "Password and Confirm password not match";
      error = true;
    }

    setErrors(errorObj);

    if (!error) {
      setIsLoading(true);
      createPassword({
        variables: {
          password: confirmPassword,
        },
      })
        .then(({ data }) => {
          localStorage.removeItem("reset_token");
          // toast.success("Password set sucessfully");
          if (data && data.createPassword && data.createPassword.user) {
            localStorage.setItem('username', data.createPassword.user.email);
            localStorage.setItem('log', hashPassword(confirmPassword));
            setIsPasswordSet(true);
            setTimeout(gotoLogin, 3000);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <div className="Main-Section">
            <div>
              <div className="Login-Content">
                <div className="Login-Logo">
                  <img src="/images/HealthMov-Logo.png" alt="" />
                </div>

                <div className="Login-Btn-Cust-Form">
                  {isPasswordSet ? (
                    <>
                      <h2>Congratulations</h2>
                      <p>Your password has been successfully set</p>
                      <p>
                        In a few seconds, you will be securely logged in and
                        automatically redirected to the home page.
                      </p>
                    </>
                  ) : (
                    <>
                      <h2>Set your password</h2>

                      <div className="Login-Form">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label"
                          >
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={password}
                            id="floatingPassword"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          {errors.password && (
                            <div className="error-msg">{errors.password}</div>
                          )}
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label"
                          >
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={confirmPassword}
                            id="floatingPassword"
                            placeholder="Confirm Password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                          {errors.confirmPassword && (
                            <div className="error-msg">
                              {errors.confirmPassword}
                            </div>
                          )}
                        </div>
                      </div>
                      <button
                        className="btn-main mt-3"
                        onClick={passwordSubmit}
                      >
                        Set my password
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="Main-Footer mb-3 mt-2">
              <p>Copyright 2023 © HealthMov All rights reserved.</p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CreatePasswordComponent;
