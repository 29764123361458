import { Buffer } from "buffer";

export const DOWNLOAD_APP_URL =
  "https://r.healthmov.com/stores";

export const findFromDate = (days) => {
  var date = new Date();
  var last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
  return last;
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const dateDiff = (fromDate, toDate) => {
  const date1 = new Date(fromDate);
  const date2 = new Date(toDate);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const headerVisible = () => {
  return (
    window.location.pathname !== "/set_password" &&
    window.location.pathname !== "/reset_password" &&
    window.location.pathname !== "/signup" &&
    window.location.pathname !== "/hollard-wellness-day" &&
    window.location.pathname !== "/hollard-moz" &&
    window.location.pathname !== "/gitex-2023" &&
    window.location.pathname !== "/billing-success" &&
    window.location.pathname !== "/billing-canceled" &&
    window.location.pathname !== "/waiting" &&
    window.location.pathname !== "/accept_invitation" &&
    window.location.pathname !== "/login" &&
    !window.location.pathname.startsWith("/redirect/")
  );
};

export const isValidEmail = (email) => {
  const emailRegEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email.match(emailRegEx);
};

export const handleSingleExport = (data, title) => {
  return handleMutlipleExport([data], title);
};
export const handleMutlipleExport = (dataArray, title) => {
  let csvContent = ["data,sections,value,percentage"];

  const generateCSVContent = (dataset, title) => {
    const totalValue = dataset.series.reduce(
      (total, item) => total + item.value,
      0
    );
    const rows = dataset.series.map((item) => {
      const percentage = ((item.value / totalValue) * 100).toFixed(2);
      return `"${dataset.text}","${item.text}",${item.value},${percentage}`;
    });
    csvContent.push(...rows);
  };

  const multipleExport = dataArray.length > 1;

  dataArray.forEach(generateCSVContent, title);

  const blob = new Blob([csvContent.join("\n")], {
    type: "text/csv;charset=utf-8;",
  });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  const timestamp = new Date().toISOString().slice(0, 19).replace("T", "_");
  const windowTitle = window.location.pathname.split("/")[1]
  const csvTitle = title || windowTitle
  link.download = multipleExport
    ? `${csvTitle}_${timestamp}.csv`
    : `${dataArray[0].text}_${timestamp}.csv`;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const hashPassword = (password) => {
  return Buffer.from(password).toString("base64");
};

export const decodePassword = (hashedPassword) => {
  return Buffer.from(hashedPassword, "base64").toString("ascii");
};
