import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import LoadingComponent from "../components/shared/loadingComponent";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as ClipboardIcon } from "bootstrap-icons/icons/clipboard.svg";
import { ReactComponent as ClipboardCheckIcon } from "bootstrap-icons/icons/clipboard-check.svg";
import { tr } from "../components/labels";
import { useSelector } from "react-redux";


function ReferPage() {
  const selectedCompany = useSelector(
    (state) => state.company.company
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedUrl, setIsCopiedUrl] = useState(false);

  useEffect(() => {
    if (selectedCompany) {
      setIsLoading(false);
    }
  }, [selectedCompany]);

  const copyClicked = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const copyUrlClicked = () => {
    setIsCopiedUrl(true);
    setTimeout(() => {
      setIsCopiedUrl(false);
    }, 2000);
  };
  const websiteUrl = "https://www.healthmov.com";

  return isLoading || !selectedCompany ? (
    <LoadingComponent />
  ) : (
    <Container fluid="md" className="home">
      <Row className="refer-title">
        <Col>
          <h1>{tr("paysMoreHealthyLabel")}!</h1>
          <p>{tr("referCommissionLabel")}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Title className="commission-title">
              {tr("commissionRatesLabel")}
            </Card.Title>
            <Table bordered hover={false}>
              <thead>
                <tr>
                  <th>{tr("planLabel")}</th>
                  <th>Starter</th>
                  <th>{tr("advancedLabel")}</th>
                  <th>Elite</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{tr("resellerCommissionLabel")}</td>
                  <td>5%</td>
                  <td>10%</td>
                  <td>12.5%</td>
                </tr>
                <tr>
                  <td>5% {tr("additionalVolumeCommissionLabel")}</td>
                  <td>500+ {tr("usersLabel")}</td>
                  <td>400+ {tr("usersLabel")}</td>
                  <td>300+ {tr("usersLabel")}</td>
                </tr>
                <tr>
                  <td>{tr("customerMonthlySubscriptionLabel")}</td>
                  <td>$10</td>
                  <td>$15</td>
                  <td>$20</td>
                </tr>
                <tr>
                  <td>{tr("customerYearlySubscriptionLabel")}</td>
                  <td>$110</td>
                  <td>$165</td>
                  <td>$220</td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: "15px" }}>
        <Col>
          <Card className="min-height-150">
            <Card.Title className="commission-example">
              {tr("starterPlanExampleLabel")}
            </Card.Title>
            <Card.Body>
              {tr("forOneLabel")} <i>Starter yearly</i>{" "}
              {tr("saleWith100EmployeesLabel")},
              <br />
              {tr("youWillEarnLabel")}:
              <br />
              <strong>$550 {tr("atSubscriptionLabel")}</strong>
              <br />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="min-height-150">
            <Card.Title className="commission-example">
              {tr("elitePlanExampleLabel")}
            </Card.Title>
            <Card.Body>
              {tr("forOneLabel")} <i>Elite monthly</i>{" "}
              {tr("saleWith300EmployeesLabel")},
              <br />
              {tr("youWillEarnLabel")}:
              <br />
              <strong>{tr("monthlyEarningsLabel")}</strong>
              <br />({tr("yearlyEarningsLabel")})
              <br />
              <strong>+ $3,600 {tr("volumeCommissionLabel")}</strong>
              <br />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 3 }}>
          <h3 style={{ marginTop: "20px" }}>{tr("boostIncomeLabel")}:</h3>
          <br />
        </Col>
      </Row>
      <Row className="mgb-20">
        <Col md={{ span: 8, offset: 3 }}>
          1/ {tr("inviteNetworkLabel")}:
          <div className="website-copy">
            <a href={websiteUrl}>{websiteUrl}</a>
            <CopyToClipboard text={websiteUrl} onCopy={copyUrlClicked}>
              <Button>
                {isCopiedUrl ? (
                  <>
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      show={true}
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          {tr("copiedLabel")}
                        </Tooltip>
                      }
                    >
                      <ClipboardCheckIcon />
                    </OverlayTrigger>
                  </>
                ) : (
                  <ClipboardIcon />
                )}
              </Button>
            </CopyToClipboard>
          </div>
        </Col>
      </Row>
      <Row className="mgb-20">
        <Col md={{ span: 8, offset: 3 }}>
          2/ {tr("referralCodeAtSignupLabel")}:
          <div className="referral-code">
            {selectedCompany.referralCode.code}
            <CopyToClipboard
              text={selectedCompany.referralCode.code}
              onCopy={copyClicked}
            >
              <Button>
                {isCopied ? (
                  <>
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      show={true}
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          {tr("copiedLabel")}
                        </Tooltip>
                      }
                    >
                      <ClipboardCheckIcon />
                    </OverlayTrigger>
                  </>
                ) : (
                  <ClipboardIcon />
                )}
              </Button>
            </CopyToClipboard>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ReferPage;
