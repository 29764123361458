import { createSlice } from "@reduxjs/toolkit";

function loadFromLocalStorage() {
  return JSON.parse(
    localStorage.getItem("selectedCompany") || "{}"
  );
}

export const companySlice = createSlice({
  name: "company",
  initialState: {
    company: loadFromLocalStorage()
  },
  reducers: {
    setCompany: (state, action) => {
      state.company = action.payload;
      localStorage.setItem("selectedCompany", JSON.stringify(action.payload));
    },
  },
});

export const { setCompany } = companySlice.actions;

export default companySlice.reducer;
