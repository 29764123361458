import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Row } from "react-bootstrap";
import LoadingComponent from "../shared/loadingComponent";
import { useQuery, useMutation } from "@apollo/client";
import { GET_INVITATION } from "../../GraphQL/queries";
import { ACCEPT_INVITATION } from "../../GraphQL/mutations";
import OnboardComponent from "../shared/onboardComponent";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { isValidEmail } from "../utils";

const AcceptInvitationComponent = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const [isLoading, setIsLoading] = useState(true);
  const uuid = params.uuid;
  const [errors, setErrors] = useState({});
  const [error, setError] = useState();
  const [isInvited, setIsInvited] = useState(false);
  const [canUseApp, setCanUseApp] = useState(false);
  const [canChangeEmail, setCanChangeEmail] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [formData, setFormData] = useState({
    uuid: params.uuid,
    phoneNumber: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
  });

  const { data } = useQuery(GET_INVITATION, {
    variables: { uuid: uuid },
    fetchPolicy: "no-cache",
  });
  const [acceptInvitation] = useMutation(ACCEPT_INVITATION, {
    fetchPolicy: "no-cache",
  });
  const onRunMutation = (method, methodName, variables) => {
    document.body.style.cursor = "wait";
    method({
      variables: variables || {},
    })
      .then(({ data }) => {
        document.body.style.cursor = "default";
        if (data[methodName].error) {
          toast.error(data[methodName].error);
        } else {
          if (data[methodName].ok) {
            toast.success(data[methodName].success);
            if (uuid === data[methodName].uuid) {
              window.location.reload();
            } else {
              window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}accept_invitation?uuid=${data[methodName].uuid}`;
            }
          } else {
            toast.error(data[methodName].error);
          }
        }
      })
      .catch((err) => {
        document.body.style.cursor = "default";
        if (methodName in err) {
          toast.error(err[methodName].error);
        } else {
          toast.error(err.message);
        }
      });
  };
  const setPhoneNumber = (value) => {
    setFormData({ ...formData, phoneNumber: value });
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleTermsAccepted = (event) => {
    setTermsAccepted(event.target.checked);
  };
  const onAcceptInvitation = () => {
    onRunMutation(acceptInvitation, "acceptInvitation", formData);
  };
  useEffect(() => {
    if (data) {
      const { getInvitation } = data;
      if (!getInvitation) {
        setError("This invitation is not valid !");
      } else {
        if (getInvitation.acceptedAt) {
          setIsInvited(true);
          setCanUseApp(getInvitation.canUseApp);
        }
        if (getInvitation.email) {
          setCanChangeEmail(false);
          setFormData({ ...formData, email: getInvitation.email });
        }
      }
      setIsLoading(false);
    }
  }, [data, formData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    let error = false;
    const errorObj = {};

    if (!termsAccepted) {
      errorObj.termsAccepted = "You need to accept the terms and conditions";
      error = true;
    }

    if (!formData.phoneNumber) {
      errorObj.phoneNumber = "Phone number is required ";
      error = true;
    } else if (!isValidPhoneNumber(formData.phoneNumber)) {
      errorObj.phoneNumber = "Phone number is not valid ";
      error = true;
    }
    if (!formData.email) {
      errorObj.email = "Email is required ";
      error = true;
    }
    if (!isValidEmail(formData.email)) {
      errorObj.email = "Email is not valid ";
      error = true;
    }
    if (!formData.firstName) {
      errorObj.firstName = "First name is required ";
      error = true;
    }
    if (!formData.lastName) {
      errorObj.lastName = "Last name is required ";
      error = true;
    }
    setErrors(errorObj);

    if (!error) {
      onAcceptInvitation();
    }
  };

  return isLoading ? (
    <LoadingComponent />
  ) : (
    <Container fluid className="app-container">
      <Row className="justify-content-center align-items-center min-vh-80">
        <div className="Login-Logo">
          <img src="/images/HealthMov-Logo.png" alt="" />
        </div>
        <div className="Login-Btn-Cust-Form">
          {error ? (
            <>
              <h2 class="error">⚠️ Error ⚠️</h2>
              <p>{error}</p>
            </>
          ) : isInvited ? (
            <>
              <h2>Congratulations 👏</h2>
              <p>Invitation accepted.</p>
              <OnboardComponent
                canUseApp={canUseApp}
                message="Thank your for your patience. You will receive a Welcome email once your organisation is approved."
              />
            </>
          ) : (
            <>
              <h2>Set your details to validate your invitation</h2>
              <form className="Login-Form">
                <div>
                  <div className="row">
                    <div>
                      <label className="form-label" htmlFor="email">
                        Your Email
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        id="email"
                        name="email"
                        required
                        disabled={!canChangeEmail}
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <div className="error-msg">{errors.email}</div>
                      )}
                    </div>
                    <label className="form-label" htmlFor="email">
                      Your Name
                    </label>
                    <div className="col-6">
                      <input
                        className="form-control"
                        type="firstName"
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        required
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                      {errors.firstName && (
                        <div className="error-msg">{errors.firstName}</div>
                      )}
                    </div>
                    <div className="col-6">
                      <input
                        className="form-control"
                        type="lastName"
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        required
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                      {errors.lastName && (
                        <div className="error-msg">{errors.lastName}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <label className="form-label">Your phone number</label>
                  <PhoneInput
                    className="form-control"
                    placeholder="Your Phone Number"
                    value={formData.phoneNumber || ""}
                    id="phoneNumber"
                    required
                    withCountryCallingCode
                    defaultCountry="AE"
                    onChange={setPhoneNumber}
                  />
                  {errors.phoneNumber && (
                    <div className="error-msg">{errors.phoneNumber}</div>
                  )}
                </div>
                <div className="t_and_c">
                  <label className="form-label" htmlFor="terms-checkbox">
                    <input
                      type="checkbox"
                      id="terms-checkbox"
                      name="termsAccepted"
                      checked={termsAccepted}
                      onChange={handleTermsAccepted}
                    />
                    I have read and agree to the{" "}
                    <a
                      href="https://www.healthmov.com/terms-and-conditions/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      terms and conditions
                    </a>
                    .
                  </label>
                  {errors.termsAccepted && (
                    <div className="error-msg">{errors.termsAccepted}</div>
                  )}
                </div>

                <button
                  className="btn-main"
                  style={{ marginTop: "15px" }}
                  onClick={handleSubmit}
                >
                  Accept invitation
                </button>
              </form>
            </>
          )}
        </div>
      </Row>
    </Container>
  );
};

export default AcceptInvitationComponent;
