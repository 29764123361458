import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import "../common.css";

function LoadingComponent() {
  return (
    <div className="loading-div">
      <FontAwesomeIcon icon={faCircleNotch} spin size="4x" />
      <p>Loading...</p>
    </div>
  );
}

export default LoadingComponent;
