import React, { useEffect, useState } from "react";
import "./loginSSO.css";
import { Container, Row } from "react-bootstrap";
import {
  LOGIN_USER_MUTATION,
  GOOGLE_LOGIN_MUTATION,
} from "../../GraphQL/mutations";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingComponent from "../shared/loadingComponent";
import { Link } from "react-router-dom";
import { tr } from "../labels";
import LanguageDropdown from "../dropdownLanguage";
import { getCurrentLanguage } from "../Translation";
import useUpdateTranslations from "../Translation";
import { hashPassword, decodePassword } from "../utils";

toast.configure();

function LoginSSO() {
  const [username, setUserName] = useState(null);
  const [password, setPassword] = useState("");
  const [isRememberMe, setIsRememberMe] = useState(false);
  let errorsObj = { username: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [loginUser] = useMutation(LOGIN_USER_MUTATION);
  const [googleLoginUser] = useMutation(GOOGLE_LOGIN_MUTATION);
  const [isLoading, setIsLoading] = useState(false);

  const updateTranslations = useUpdateTranslations();

  useEffect(() => {
    if (localStorage.password) {
      localStorage.clear();
    }

    if (localStorage.checkbox && localStorage.username !== "" && username === null) {
      setIsRememberMe(true);
      setUserName(localStorage.username);
      setPassword(decodePassword(localStorage.log));
    }
    updateTranslations(getCurrentLanguage());
  }, [updateTranslations]);

  const onLogin = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (!username) {
      errorObj.username = "Email is required";
      error = true;
    }

    if (username !== "") {
      error = /$^|.+@.+..+/.test(username) ? false : true;

      if (error) errorObj.nusername = "Invalid Email";

      // if (username.toLowerCase().includes("@healthmov.com")) { errorObj.nusername = "Please sign-in with SSO (see button below)"; error = true }
    }

    if (!password) {
      errorObj.password = "Password  is required ";
      error = true;
    }

    setErrors(errorObj);

    if (!error) {
      setIsLoading(true);
      loginUser({
        variables: {
          username: username,
          password: password,
        },
      })
        .then(({ data }) => {
          if (
            data !== undefined &&
            (data?.login?.accessToken != null ||
              data?.login?.accessToken !== "")
          ) {
            localStorage.setItem("token", data.login.accessToken);
            if (isRememberMe) {
              localStorage.setItem("username", username);
              localStorage.setItem("log", hashPassword(password));
              localStorage.setItem("checkbox", isRememberMe);
            }
            window.location.href = "/home";
          }
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  window.onLoginSSO = (res) => {
    console.log(res);
    // console.log(jwt_decode(res.credential))
    const googleToken = res.credential;
    googleLoginUser({
      variables: {
        token: googleToken,
      },
    })
      .then(({ data }) => {
        console.log("Data", data);
        if (
          data !== undefined &&
          (data?.loginSSO?.accessToken != null ||
            data?.loginSSO?.accessToken !== "")
        ) {
          localStorage.setItem("token", data.loginSSO.accessToken);
          localStorage.setItem("username", username);
          localStorage.removeItem("log");
          window.location.href = "/home";
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const onChangeCheckbox = (event) => {
    setIsRememberMe(event.target.checked);
  };

  const shouldDisplayLanguageDropdown = !window.location.href.startsWith(
    "https://portal.healthmov.com"
  );

  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <Container fluid className="app-container">
            <Row className="justify-content-center align-items-center min-vh-80">
              <div className="Login-Logo">
                <img src="/images/HealthMov-Logo.png" alt="" />
              </div>
              <div>
                <div className="Login-Btn-Cust-Form">
                  <h2>{tr("logInToLabel")} HealthMov</h2>
                  <div className="Login-Form">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        {tr("emailLabel")}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        value={username}
                        id="exampleFormControlInput1"
                        placeholder="email@healthmov.com"
                        onChange={(e) => setUserName(e.target.value)}
                        required
                      />
                      {errors.username && (
                        <div className="error-msg">{errors.username}</div>
                      )}
                      {errors.nusername && (
                        <div className="error-msg">{errors.nusername}</div>
                      )}
                    </div>

                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="form-label"
                      >
                        {tr("passwordLabel")}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        value={password}
                        id="floatingPassword"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      {errors.password && (
                        <div className="error-msg">{errors.password}</div>
                      )}
                    </div>
                  </div>
                  <div className="RememberForgotCust">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={onChangeCheckbox}
                        checked={isRememberMe}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        {tr("rememberMeLabel")}
                      </label>
                    </div>
                    <Link to="forgotpassword">
                      {tr("forgotPasswordLabel")}?
                    </Link>
                  </div>
                  <button className="btn-main" onClick={onLogin}>
                    {tr("logInLabel")}
                  </button>
                </div>
              </div>
            </Row>
            <div className="Main-Footer mb-3 mt-2">
              <p>Copyright 2023 © HealthMov All rights reserved.</p>
              {shouldDisplayLanguageDropdown && <LanguageDropdown log={true} />}
            </div>
          </Container>
        </>
      )}
    </>
  );
}

export default LoginSSO;
