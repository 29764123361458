import React, { useEffect, useState } from "react";
import "./components/common.css";
import "./App.css";
import LoadingComponent from "./components/shared/loadingComponent";
import { initializeApp } from "firebase/app";
import {
  getRemoteConfig,
  getBoolean,
  fetchAndActivate,
} from "firebase/remote-config";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCUz6zVSc7YN8j-DGLO34ijLKfaMn6jFmo",
  authDomain: "healthmov-app.firebaseapp.com",
  projectId: "healthmov-app",
  storageBucket: "healthmov-app.appspot.com",
  messagingSenderId: "405716098073",
  appId: "1:405716098073:web:ebdb32ae2705f66b6d5e88",
  measurementId: "G-R7XJMDK259",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const local = window.location.host.indexOf("-local") > 0;
const dev = window.location.host.indexOf("-dev") > 0;
const staging = window.location.host.indexOf("-staging") > 0;
const env = local
  ? "local"
  : dev
  ? "development"
  : staging
  ? "staging"
  : "production";

function MaintenanceLayout({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isMaintenance, setIsMaintenance] = useState(false);

  useEffect(() => {
    const remoteConfig = getRemoteConfig(app);
    remoteConfig.settings.minimumFetchIntervalMillis = 60 * 1000;
    const key = `portal_active_${env}`;
    // console.log("Key=", key)
    fetchAndActivate(remoteConfig)
      .then(() => {
        const value = getBoolean(remoteConfig, key);
        // console.log("Value=", value)
        setIsMaintenance(!value);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return isLoading ? (
    <LoadingComponent />
  ) : isMaintenance ? (
    <>
      <div className="Main-Section">
        <div style={{ marginTop: "25px" }}>
          <div className="Login-Logo">
            <img src="/images/HealthMov-Logo.png" alt="" />
          </div>
          <div style={{ maxWidth: "900px", marginTop: "50px" }}>
            <h3>Your App Needs Some TLC</h3>
            <br />
            <p>
              Just like you, our app needs a little maintenance every now to
              stay healthy and running smoothly. So we're taking a wellness
              break to give it the Tender love and care it deserves.
            </p>
            <p>
              During this time, please take the opportunity to meditate,
              stretch, and maybe even have a cup of tea. We hope you're taking
              care of yourself too!
            </p>
            <p>Check back soon for a rejuvenated HealthMov App 😉</p>
            <img
              width="500"
              src="https://cdn.healthmov.com/images/maintenance.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <>{children}</>
  );
}

export default MaintenanceLayout;
