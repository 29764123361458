import React, { useState, useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import 'react-multi-email/dist/style.css'
import 'react-datepicker/dist/react-datepicker.css'
import '../common/tools.css'
import { useLazyQuery } from '@apollo/client'
import { LOAD_DEPENDENTS } from '../../GraphQL/queries'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import FilterableDataTable from '../common/filterableDataTable'
import LoadingComponent from '../shared/loadingComponent'
import { tr } from '../labels'
import { useSelector } from 'react-redux'

toast.configure()

function DependentsComponent() {
  const selectedCompany = useSelector((state) => state.company.company)
  const profileObj = useSelector((state) => state.profile.profile)
  const { user } = profileObj || {}
  const [isLoading, setIsLoading] = useState(true)
  const [currentCompanyId, setCurrentCompanyId] = useState(null)

  const fetchDependents = (data) => {
    if (data) {
      const { dependentInfos } = data || []
      var dependents = []
      for (const [idx, di] of Object.entries(dependentInfos)) {
        console.log(di)
        const obj = {
          Id: parseInt(idx) + 1,
          'Parent First Name': di.employee.firstName,
          'Parent Last Name': di.employee.lastName,
          'First Name': di.firstName,
          'Last Name': di.lastName,
          Birthdate: di.birthdate,
          'Onboarding date': di.dateOfOnboarding,
          'Offboarding date': di.dateOfOnboarding,
          _uuid: di.id,
          'Is Registered': !!di.dependentEmployee ? 'Yes' : 'No',
          _colorRow: di.dependentEmployee ? 'success' : '',
          Email: di.dependentEmployee?.user?.email,
        }
        dependents.push(obj)
      }
      setTableData(dependents)
      setIsLoading(false)
    }
  }

  const [loadDependents] = useLazyQuery(LOAD_DEPENDENTS, {
    fetchPolicy: 'no-cache',
    variables: { companyId: selectedCompany.id },
    onCompleted: (data) => {
      fetchDependents(data)
    },
    onError: (err) => {
      document.body.style.cursor = 'default'
      toast.error(err.message)
    },
  })

  useEffect(() => {
    if (
      selectedCompany &&
      selectedCompany.id &&
      selectedCompany.id !== currentCompanyId
    ) {
      setCurrentCompanyId(selectedCompany.id)
      loadDependents()
    }
  }, [selectedCompany, loadDependents, currentCompanyId])
  const [tableData, setTableData] = useState([])

  const conditionalRowStyles = [
    {
      when: (row) => row['_registered'],
      style: {
        backgroundColor: 'rgba(255, 144, 45, 0.3)',
        color: 'black',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ]

  if (isLoading) return <LoadingComponent />

  return (
    <Container fluid="md">
      {tableData.length === 0 ? (
        <div className="home-subtitle-2">{tr('noDependentsInfo')}</div>
      ) : (
        <Row>
          <FilterableDataTable
            title="Dependents"
            data={tableData}
            selectableRows={false}
            conditionalRowStyles={conditionalRowStyles}
          />
        </Row>
      )}
    </Container>
  )
}

export default DependentsComponent
