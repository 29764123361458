import React from "react";
import { Button, Container, Row } from "react-bootstrap";

function SuccessPage() {
  const gotoBilling = () => {
    localStorage.removeItem("selectedCompany")
    localStorage.removeItem("selectedOptions")
    localStorage.removeItem("filterObj")
    window.location.href = "/account-billing";
  };
  setTimeout(gotoBilling, 3000);

  return (
    <Container fluid className="app-container">
      <Row className="justify-content-center align-items-center min-vh-80">
        <div className="Login-Logo">
          <img src="/images/HealthMov-Logo.png" alt="" />
        </div>

        <div className="Login-Btn-Cust-Form">
          <h2>Congratulations</h2>

          <p>Your payment has been approved</p>
          <Button variant="primary main" onClick={gotoBilling}>
            Go to Billing
          </Button>
        </div>
      </Row>
      <div className="Main-Footer mb-3 mt-2">
        <p>Copyright 2023 © HealthMov All rights reserved.</p>
      </div>
    </Container>
  );
}

export default SuccessPage;
