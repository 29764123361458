import React, { useEffect } from "react";
import { useSelector } from "react-redux";

function WaitingPage() {
  const profileObj = useSelector(
    (state) => state.profile.profile
  );
  const { user } = profileObj || {};

  useEffect(() => {
    if (user && user.canUsePortal) {
      window.location.href = "/";
    }
  }, [user]);

  return (
    <div className="Main-Section">
      <div>
        <div className="Login-Content">
          <div className="Login-Logo">
            <img src="/images/HealthMov-Logo.png" alt="" />
          </div>

          <div className="Login-Btn-Cust-Form">
            <h2>Account is awaiting approval</h2>

            <p>
              Thank your for your patience. Your trial period is awaiting
              approval
            </p>
            <p>Stay tuned 😉</p>
          </div>
        </div>
      </div>
      <div className="Main-Footer mb-3 mt-2">
        <p>Copyright 2023 © HealthMov All rights reserved.</p>
      </div>
    </div>
  );
}

export default WaitingPage;
