import React from "react";
import HeaderComponent from "../components/header/headerComponent";

function Header({ filterObj, setFilterObj, toggleHandler, isCollapse }) {
  return (
    <HeaderComponent
      filterObj={filterObj}
      setFilterObj={setFilterObj}
      toggleHandler={toggleHandler}
      isCollapse={isCollapse}
    />
  );
}

export default Header;
