import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useLazyQuery } from '@apollo/client'
import PieChart from '../components/shared/pieChartComponent'
import HorizontalBarChart from '../components/shared/horizontalBarChartComponent'
import LoadingComponent from '../components/shared/loadingComponent'
import moment from 'moment'
import {
  LOAD_COMPANY_STATS,
  GET_CHALLENGE_LEADERBOARD,
} from '../GraphQL/queries'
import constants from '../components/shared/constants'
import ScrollToTop from '../components/ScrollToTop/ScrollToTop'
import DemographicsIcon from '../icons_analytics/Mask group.svg'
import EngagementIcon from '../icons_analytics/flame-icon.svg'
import { handleMutlipleExport, handleSingleExport } from '../components/utils'
import { ReactComponent as DownloadIcon } from 'bootstrap-icons/icons/download.svg'

import { useSelector } from 'react-redux'

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  }
  return date.toLocaleString('en-US', options)
}

function EngagementPage() {
  const selectedCompany = useSelector((state) => state.company.company)
  const [analytics, setAnalytics] = useState([])
  const [currentCompanyId, setCurrentCompanyId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([])
  const [title, setTitle] = useState(null)

  const [loadAnalyticsQuery] = useLazyQuery(LOAD_COMPANY_STATS)
  const [getChallengeLeaderboard] = useLazyQuery(GET_CHALLENGE_LEADERBOARD)

  const loadAnalyticsCallback = useCallback(() => {
    if (
      selectedCompany &&
      selectedCompany.id &&
      selectedCompany.id !== currentCompanyId
    ) {
      loadAnalyticsQuery({
        fetchPolicy: 'no-cache',
        variables: { id: selectedCompany.id },
        onCompleted: (data) => {
          if (data) {
            const updatedStats = data.company.stats.map((stat) => {
              if (!stat.showZeros) {
                return {
                  ...stat,
                  series: stat.series.filter((serie) => serie.value !== 0),
                }
              }
              return stat
            })

            const filteredStats = updatedStats.filter((stat) => {
              return stat.section.includes('Engagement')
            })

            console.log(updatedStats)
            setAnalytics(filteredStats)
            setIsLoading(false)
          }
        },
        onError: (error) => {
          console.error(error)
          setIsLoading(false)
        },
      })
    }
  }, [loadAnalyticsQuery, selectedCompany, currentCompanyId])

  useEffect(() => {
    if (selectedCompany && selectedCompany.id !== currentCompanyId) {
      setCurrentCompanyId(selectedCompany.id)
      setIsLoading(true)
      loadAnalyticsCallback()
    }
  }, [isLoading, selectedCompany, currentCompanyId, loadAnalyticsCallback])

  const format_val = (raw_value, nbDecimals, unit) => {
    if (raw_value === null) {
      return null
    }
    const val = Number(raw_value.toFixed(nbDecimals || 0)).toLocaleString()
    let ret = val
    if (unit) {
      ret = `${val} ${unit}`
    }
    return ret
  }

  const convert_to_serie = (data) => {
    const ret = {
      title: data.text,

      data: data.series
        .map((row, index) => {
          return {
            ...row,
            nbDecimals: data.nbDecimals,
            row_color: row.color,
            color: constants.COLORS[row.color],
          }
        })
        .sort((a, b) => a.value - b.value),
    }
    return ret
  }

  const format_info = (data) => {
    if (data.dimensionText) {
      return `${format_val(data.nbMeasures, 0)} ${
        data.dimensionText
      } from ${format_val(data.nbUsers, 0)} users`
    }
    return `${format_val(data.nbUsers, 0)} users`
  }

  const groupBySection = (analytics) => {
    return analytics.reduce((acc, cur) => {
      if (!acc[cur.section]) {
        acc[cur.section] = []
      }
      acc[cur.section].push(cur)
      return acc
    }, {})
  }

  const sectionRefs = useRef({})

  function getSectionIcon(section) {
    switch (section) {
      case 'Demographics':
        return DemographicsIcon
      case 'Engagement':
        return EngagementIcon
      default:
        return null
    }
  }

  const isFakeInAnalytics = () => {
    return analytics.some((analytic) => analytic.isFake)
  }

  const onClick = (data, dataContext) => {
    if (data.dimension === 'spotlight') {
      console.log(
        "We're going to display the leaderboard of ",
        dataContext.text
      )
      getChallengeLeaderboard({
        fetchPolicy: 'no-cache',
        variables: { companyId: selectedCompany.id, title: dataContext.text },
        onCompleted: (data) => {
          console.log('Leaderboard data:', data)
          const challenge = data.challengeFromTitle
          const edges = challenge.leaderboard.players.edges
          const rows = []
          for (const [key, item] of Object.entries(edges)) {
            const node = item.node
            const startedAt = moment
              .utc(node.startedAt)
              .local()
              .format('YYYY-MM-DD HH:mm')
            rows.push([
              node.ranking,
              node.employee.fullName,
              node.employee.user.email,
              node.value,
              challenge.unit,
              startedAt,
            ])
          }
          const columns = [
            'Ranking',
            'Employee',
            'Email',
            'Value',
            'Unit',
            'StartedAt',
          ]
          setColumns(columns)
          setRows(rows)
          setTitle(challenge.title)
        },
      })
    }
  }

  const baseUrl = process.env.REACT_APP_API_URL.replace(
    '/graphql',
    '/csv_stats'
  )
  const link = `${baseUrl}/${selectedCompany.id}`

  return isLoading || !selectedCompany ? (
    <LoadingComponent />
  ) : (
    <Container fluid="md" className="home">
      <Row className="justify-content-center align-items-start">
        <Col>
          {isFakeInAnalytics() ? (
            <Card className="is-fake-indicator">
              ⚠️ Sample data shown. Real data available when employee count
              exceeds 25.
            </Card>
          ) : (
            <Row style={{ textAlign: 'right', paddingRight: '10px' }}>
              <a href={link}>Download all data</a>
            </Row>
          )}
        </Col>
      </Row>
      {Object.entries(groupBySection(analytics))
        .sort()
        .map(([section, analytics]) => (
          <div key={section} ref={(el) => (sectionRefs.current[section] = el)}>
            <Row
              xs={1}
              md={1}
              className="justify-content-center align-items-start"
            >
              <Col>
                <Card className="card-section">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: 10,
                    }}
                  >
                    <img
                      src={getSectionIcon(section)}
                      className="icon-section-title"
                      alt="section"
                    />
                    <h3 className="section-title">{section}</h3>
                    <button
                      className="btn"
                      onClick={() => handleMutlipleExport(analytics)}
                      style={{ padding: '2px 5px' }}
                    >
                      <DownloadIcon />
                    </button>
                  </div>
                  {analytics.map((data, idx) => (
                    <div style={{ padding: '15px' }} key={idx}>
                      <Container className="bg-white">
                        <Row
                          xs={1}
                          md={1}
                          className="g-4 main-steps justify-content-center align-items-start"
                        >
                          <Col className="col-title">
                            <div className="measure-title">
                              {data.text}
                              <button
                                className="btn export-buttons"
                                onClick={() => handleSingleExport(data)}
                                style={{ padding: '2px 5px' }}
                              >
                                <DownloadIcon />
                              </button>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card className="">
                              {data.nbMeasures !== 0 && (
                                <Card.Body>
                                  <div className="measure-info">
                                    <span className="strong">
                                      {format_info(data)}
                                    </span>
                                  </div>
                                </Card.Body>
                              )}
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card className="min-height-150">
                              {/* Removed individual LoadingComponent */}
                              {data.nbMeasures === 0 ? (
                                <Card.Body>
                                  <img
                                    alt={`no ${data.dimensionText}`}
                                    className="nothing"
                                    src="https://cdn.healthmov.com/app_icons/nothing.png"
                                  />
                                  <p>No {data.dimensionText} found !</p>
                                </Card.Body>
                              ) : (
                                <Card.Body>
                                  {data.barChart ? (
                                    <HorizontalBarChart
                                      chartId={data.dimension}
                                      serie={convert_to_serie(data)}
                                      width={'100%'}
                                      height={50 + data.series.length * 30}
                                      legendWidth={'100%'}
                                      legendHeight={'100%'}
                                      onClick={(dataContext) =>
                                        onClick(data, dataContext)
                                      }
                                    />
                                  ) : (
                                    <PieChart
                                      chartId={data.dimension}
                                      serie={convert_to_serie(data)}
                                      width={'100%'}
                                      height={155}
                                    />
                                  )}
                                </Card.Body>
                              )}
                            </Card>
                            {data.dimension === 'spotlight' &&
                              columns.length > 0 && (
                                <div
                                  className="row"
                                  style={{ padding: '10px', marginTop: '10px'}}
                                >
                                  <h4>
                                    {title} - {rows.length} players
                                  </h4>
                                  <table
                                    id="metrics"
                                    className="table rounded-corners"
                                    style={{ padding: 0, marginTop: '10px' }}
                                  >
                                    <thead>
                                      <tr>
                                        {columns.map((column, index) => (
                                          <th key={index}>{column}</th>
                                        ))}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {rows.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                          {row.map((value, index) => (
                                            <td key={index + 1}>{value}</td>
                                          ))}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                          </Col>
                        </Row>
                        <span className="footer-card">
                          Data updated as on : {formatDate(data.updatedAt)}
                        </span>
                      </Container>
                    </div>
                  ))}
                </Card>
              </Col>
            </Row>
          </div>
        ))}
      <ScrollToTop />
    </Container>
  )
}

export default EngagementPage
