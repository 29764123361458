import { useMutation } from "@apollo/client";
import { TRANSLATE_STRINGS } from "../GraphQL/mutations";

const words = [
  //login
  "Log in",
  "Sign up",
  "Log in to",
  "Password",
  "Remember me",
  "Forgot password",
  "Email",
  "email",
  //sign up
  "Start your free trial now",
  "Company Name",
  "Company Website URL",
  "Company Size",
  "Your Name",
  "First Name",
  "Last Name",
  "Your Email",
  "Your phone number",
  "Referral code",
  "I have read and agree to the",
  "terms and conditions",
  "Already signed-up?",
  "Go to Login",
  "Sign up",
  "Sign up complete",
  "Please check your inbox",
  "Select one",
  "You're going to be automatically redirected to the store in 3 seconds",
  //navbar
  "Welcome to HealthMov",
  "Home",
  "Users",
  "Reports",
  "Engagement",
  "Refer",
  "Global",
  "Download",
  "Account",
  "Profile",
  "Billing",
  "Sign out",
  //user page
  "Available Seats",
  "of",
  "used",
  "Buy more seats",
  "Invited Users",
  "Signed up Users",
  "Check Usage",
  "Edit Names",
  "Cancel",
  "Save",
  "Invite Users",
  "Invite by link",
  "Copy/paste emails addresses only",
  "List of emails",
  //home page
  "Welcome to HealthMov",
  "Congratulations on starting your corporate Health and Wellness Journey and thank you for your interest in HealthMov.",
  "We hope you and your colleagues enjoy your free trial of HealthMov, please do let us know at",
  "if you have any questions about inviting users or selecting your preferred plan.",
  "Congratulations on starting your corporate Health and Wellness Journey",
  "and thank you for choosing HealthMov.",
  "Please let us know at",
  "if you have any questions, feedback or requests.",
  "Available seats",
  "Used seats",
  "Non Signed Up",
  "Onboarded",
  "Invitations",
  "Accepted",
  "Pending",
  "Signed up",
  "Subscribe",
  "Buy more seats",
  "Invite",
  "Total Users",
  //profile
  "Edit Profile",
  "Settings",
  "Informations",
  "Change my password",
  "Old Password",
  "New password",
  "Confirm password",
  "Automatic reports send to my email",
  "Once per week",
  "Once per month",
  "None",
  "Confirm",
  //refers
  "It pays MORE to be Healthy and refer others",
  "Refer HealthMov to your network, clients or partners and earn commission on every user that signs up",
  "Commission rates",
  "Plan",
  "Starter",
  "Advanced",
  "Elite",
  "Reseller Commission",
  "additional Volume commission (end of year)",
  "Customer Monthly Subscription",
  "Customer Yearly Subscription",
  "Starter plan example",
  "For 1",
  "sale with 100 employees",
  "you will earn",
  "at subscription",
  "Elite plan example",
  "sale with 300 employees",
  "$750 per month for 12 months",
  "$9,000 over the year",
  "volume commission",
  "Only 2 steps to boost your income",
  "Invite your network to visit our website",
  "Copied",
  "Give them your referral code to use at signup",
  //billing
  "Rewards Redemption",
  "per month",
  "Health",
  "Monthly Health Scans",
  "Risk Analysis",
  "Measure Analysis",
  "Analytics",
  "Population analysis",
  "Engagement analysis",
  "Health analysis",
  "Dietary Analysis",
  "Unlimited Manual Food log",
  "Meal Scans",
  "Nutritional Analysis",
  "Challenges",
  "Individual Challenges",
  "Group Leaderboard",
  "Onboarding Raffle",
  "Department Leaderboard",
  "Bonus points",
  "Gift cards",
  "200+ gift cards",
  "20+ countries covered",
  "Fitness / Wearables",
  "Fitness recommandations",
  "Sleep recommandations",
  "Yearly",
  "Monthly",
  "Select your preferred Health and Wellness plan",
  "Get started today and you can cancel anytime",
  "Free",
  "seat",
  "year",
  "month",
  "seats active",
  //
  "Current",
  "Bill",
  "Manage",
  "Seats",
  "Will be canceled on",
  "Next payment due",
  "View payment history",
  "Current plan",
  "new seat",
  "seat",
  "prorated",
  "for",
  "Add",
  "Charge to your card",
  "each",
  //delete account
  "You can close your account via this",
  "link",
  "Close your account",
  "You're going to close your account",
  "You and your invited users won't be able to access the HealthMov app.",
  "You will be signed out",
  "This action cannot be undone.",
  "Are you sure?",
  "Yes, Close my account",
  //subrcribe plan
  "Subscribe to the plan",
  "How many seats do you want to purchase at",
  "mininum",
  "Buy",
  "seats for",
  //download page
  "Download the HealthMov app",
  "It pays to be",
  "Healthy",
  //lock page
  "You need access",
  "Request access, or switch account with access",
  //invoice page
  "Invoice",
  "Submit",
  "Select a month",
];

export const getCurrentLanguage = () => {
  const DEFAULT_LANGUAGE = "en";
  return localStorage.getItem("language") || DEFAULT_LANGUAGE;
};

const useUpdateTranslations = (callback) => {
  const [translateStrings] = useMutation(TRANSLATE_STRINGS);

  const updateTranslations = (newLanguage) => {
    const currentLanguage = getCurrentLanguage();
    if (newLanguage && newLanguage !== currentLanguage) {
      translateStrings({
        variables: {
          languages: newLanguage,
          strings: words,
        },
        fetchPolicy: "no-cache",
      })
        .then((response) => {
          const translatedStrings = response.data.translateStrings.result;

          translatedStrings.forEach(({ _, result }) => {
            const localStorageObject = {};

            result.forEach(({ key, value }) => {
              localStorageObject[key] = value;
            });
            localStorage.setItem(
              `translatedStrings`,
              JSON.stringify(localStorageObject)
            );
          });
          localStorage.setItem("language", newLanguage);
          callback && callback();
        })
        .catch((error) => {
          console.error("Error translating strings:", error);
          callback && callback();
        });
    }
  };

  return updateTranslations;
};

export default useUpdateTranslations;
