import React from "react";
import { Button } from "react-bootstrap";

function CanceledPage() {
  const gotoBilling = () => {
    window.location.href = "/account-billing";
  };
  setTimeout(gotoBilling, 5000);

  return (
    <div className="Main-Section">
      <div>
        <div className="Login-Content">
          <div className="Login-Logo">
            <img src="/images/HealthMov-Logo.png" alt="" />
          </div>

          <div className="Login-Btn-Cust-Form">
            <h2>Payment canceled</h2>

            <p>Click below to return to the billing page</p>
            <Button variant="primary main" onClick={gotoBilling}>
              Return to portal
            </Button>
          </div>
        </div>
      </div>
      <div className="Main-Footer mb-3 mt-2">
        <p>Copyright 2023 © HealthMov All rights reserved.</p>
      </div>
    </div>
  );
}

export default CanceledPage;
