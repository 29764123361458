export function getLocalStorageItem(key, property) {
  const item = JSON.parse(localStorage.getItem(key));
  return item ? item[property] : null;
}

const labels = {
  homeLabel: "Home",
  usersLabel: "Users",
  dependentsLabel: "Dependents",
  reportsLabel: "Reports",
  statsLabel: "Engagement",
  referLabel: "Refer",
  globalLabel: "Global",
  downloadLabel: "Download",
  accountLabel: "Account",
  profileLabel: "Profile",
  billingLabel: "Billing",
  signOutLabel: "Sign out",
  passwordLabel: "Password",
  logInToLabel: "Log in to",
  rememberMeLabel: "Remember me",
  logInLabel: "Log in",
  forgotPasswordLabel: "Forgot password",
  emailLabel: "Email",
  //signup
  startTrialLabel: "Start your free trial now",
  companyNameLabel: "Company Name",
  companyWebsiteLabel: "Company Website URL",
  companySizeLabel: "Company Size",
  yourNameLabel: "Your Name",
  firstNameLabel: "First Name",
  lastNameLabel: "Last Name",
  yourEmailLabel: "Your Email",
  phoneNumberLabel: "Your phone number",
  referralCodeLabel: "Referral code",
  agreeLabel: "I have read and agree to the",
  termsLabel: "terms and conditions",
  alreadysignedLabel: "Already signed-up?",
  goToLoginLabel: "Go to Login",
  signUpLabel: "Sign up",
  signUpCompleteLabel: "Sign up complete",
  pleaseCheckInboxLabel: "Please check your inbox",
  selectLabel: "Select one",
  redirectLabel:
    "You're going to be automatically redirected to the store in 3 seconds",
  //user
  availableSeatsLabel: "Available Seats",
  ofLabel: "of",
  usedLabel: "used",
  buyMoreSeatsLabel: "Buy more seats",
  invitedUsersLabel: "Invited Users",
  signedUpUsersLabel: "Signed up Users",
  checkUsageLabel: "Check Usage",
  editNamesLabel: "Edit Names",
  cancelLabel: "Cancel",
  saveLabel: "Save",
  inviteUsersLabel: "Invite Users",
  inviteByLinkLabel: "Invite by link",
  copyPasteEmailsLabel: "Copy/paste emails addresses only",
  listOfEmailsLabel: "List of emails",
  //home label
  welcomeHealthMovLabel: "Welcome to HealthMov",
  congratsStartingJourneyLabel:
    "Congratulations on starting your corporate Health and Wellness Journey and thank you for your interest in HealthMov.",
  freeTrialEnjoyLabel:
    "We hope you and your colleagues enjoy your free trial of HealthMov, please do let us know at",
  questionsPreferredPlanLabel:
    "if you have any questions about inviting users or selecting your preferred plan.",
  congratsJourneyHealthMovLabel:
    "Congratulations on starting your corporate Health and Wellness Journey",
  thankYouChoosingLabel: "and thank you for choosing HealthMov.",
  letUsKnowLabel: "Please let us know at",
  noDependentsInfo: "No dependents found",
  questionsFeedbackRequestsLabel:
    "if you have any questions, feedback or requests.",
  availableSeatsInfoLabel: "Available seats",
  usedSeatsInfoLabel: "Used seats",
  nonSignedUpLabel: "Non Signed Up",
  offboardedLabel: "Offboarded",
  onboardedLabel: "Onboarded",
  invitationsLabel: "Invitations",
  acceptedLabel: "Accepted",
  pendingLabel: "Pending",
  signedUpLabel: "Signed up",
  nonOboardedLabel: "Non onboarded",
  subscribeLabel: "Subscribe",
  buyMoreSeatsInfoLabel: "Buy more seats",
  inviteLabel: "Invite",
  totalUsersLabel: "Total Users",
  //profile
  editProfileLabel: "Edit Profile",
  settingsLabel: "Settings",
  informationsLabel: "Informations",
  changePasswordLabel: "Change my password",
  oldPasswordLabel: "Old Password",
  newPasswordLabel: "New password",
  confirmPasswordLabel: "Confirm password",
  autoReportsEmailLabel: "Automatic reports send to my email",
  oncePerWeekLabel: "Once per week",
  oncePerMonthLabel: "Once per month",
  noneLabel: "None",
  confirmLabel: "Confirm",
  //refers
  paysMoreHealthyLabel: "It pays MORE to be Healthy and refer others",
  referCommissionLabel:
    "Refer HealthMov to your network, clients or partners and earn commission on every user that signs up",
  commissionRatesLabel: "Commission rates",
  planLabel: "Plan",
  starterLabel: "Starter",
  advancedLabel: "Advanced",
  eliteLabel: "Elite",
  resellerCommissionLabel: "Reseller Commission",
  additionalVolumeCommissionLabel: "additional Volume commission (end of year)",
  customerMonthlySubscriptionLabel: "Customer Monthly Subscription",
  customerYearlySubscriptionLabel: "Customer Yearly Subscription",
  starterPlanExampleLabel: "Starter plan example",
  forOneLabel: "For 1",
  saleWith100EmployeesLabel: "sale with 100 employees",
  youWillEarnLabel: "you will earn",
  atSubscriptionLabel: "at subscription",
  elitePlanExampleLabel: "Elite plan example",
  saleWith300EmployeesLabel: "sale with 300 employees",
  monthlyEarningsLabel: "$750 per month for 12 months",
  yearlyEarningsLabel: "$9,000 over the year",
  volumeCommissionLabel: "volume commission",
  boostIncomeLabel: "Only 2 steps to boost your income",
  inviteNetworkLabel: "Invite your network to visit our website",
  copiedLabel: "Copied",
  referralCodeAtSignupLabel: "Give them your referral code to use at signup",
  //billing
  rewardsRedemptionLabel: "Rewards Redemption",
  perMonthLabel: "per month",
  healthLabel: "Health",
  monthlyHealthScansLabel: "Monthly Health Scans",
  riskAnalysisLabel: "Risk Analysis",
  measureAnalysisLabel: "Measure Analysis",
  analyticsLabel: "Analytics",
  populationAnalysisLabel: "Population analysis",
  engagementAnalysisLabel: "Engagement analysis",
  healthAnalysisLabel: "Health analysis",
  dietaryAnalysisLabel: "Dietary Analysis",
  unlimitedManualFoodLogLabel: "Unlimited Manual Food log",
  mealScansLabel: "Meal Scans",
  nutritionalAnalysisLabel: "Nutritional Analysis",
  challengesLabel: "Challenges",
  individualChallengesLabel: "Individual Challenges",
  groupLeaderboardLabel: "Group Leaderboard",
  onboardingRaffleLabel: "Onboarding Raffle",
  departmentLeaderboardLabel: "Department Leaderboard",
  bonusPointsLabel: "Bonus points",
  giftCardsLabel: "Gift cards",
  giftCardsVarietyLabel: "200+ gift cards",
  countriesCoveredLabel: "20+ countries covered",
  fitnessWearablesLabel: "Fitness / Wearables",
  fitnessRecommendationsLabel: "Fitness recommandations",
  sleepRecommendationsLabel: "Sleep recommandations",
  yearlyLabel: "Yearly",
  monthlyLabel: "Monthly",
  selectPreferredPlanLabel: "Select your preferred Health and Wellness plan",
  getStartedCancelAnytimeLabel: "Get started today and you can cancel anytime",
  freeLabel: "Free",
  seatLabel: "seat",
  yearLabel: "year",
  monthLabel: "month",
  seatActiveLabel: "seats active",
  //billing 2
  currentLabel: "Current",
  billLabel: "Bill",
  manageLabel: "Manage",
  SeatsLabel: "Seats",
  willbecancelLabel: "Will be canceled on",
  nextpaymentLabel: "Next payment due",
  viewpaymenthistoryLabel: "View payment history",
  currentplanLabel: "Current plan",
  newseatLabel: "new seat",
  proratedLabel: "prorated",
  forLabel: "for",
  AddLabel: "Add",
  chargetoyourcardLabel: "Charge to your card",
  eachLabel: "each",
  //delete account
  closeAccountInfoLabel: "You can close your account via this",
  linkLabel: "link",
  closeAccountLabel: "Close your account",
  closingAccountWarningLabel: "You're going to close your account",
  noAccessAfterClosingLabel:
    "You and your invited users won't be able to access the HealthMov app.",
  signedOutAfterClosingLabel: "You will be signed out",
  irreversibleActionLabel: "This action cannot be undone.",
  areYouSureLabel: "Are you sure?",
  yesCloseAccountLabel: "Yes, Close my account",
  //plan upgrade
  subplanLabel: "Subscribe to the plan",
  howmanyLabel: "How many seats do you want to purchase at",
  mininumLabel: "mininum",
  buyLabel: "Buy",
  seatsforLabel: "seats for",
  //download page
  downloadAppLabel: "Download the HealthMov app",
  paysLabel: "It pays to be",
  healthyLabl: "Healthy",
  //lock page
  accessLabel: "You need access",
  requestLabel: "Request access, or switch account with access",
  //invoice page
  invoiceLabel: "Invoice",
  submitLabel: "Submit",
  selectMonthLabel: "Select a month",
};

export const tr = (labelKey) => {
  const word = labels[labelKey];
  const translatedStringsLang = "translatedStrings";
  return getLocalStorageItem(translatedStringsLang, word) || labels[labelKey];
};
