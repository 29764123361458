import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function PieChart({ chartId, serie, width, height, title }) {
  useLayoutEffect(() => {
    if (chartId) {
      let chartRoot = am5.Root.new(chartId);

      chartRoot.setThemes([
        am5themes_Animated.new(chartRoot),
        am5percent.DefaultTheme.new(chartRoot),
      ]);

      let chart = chartRoot.container.children.push(
        am5percent.PieChart.new(chartRoot, {
          layout: chartRoot.verticalLayout,
          innerRadius: am5.percent(60),
        })
      );

      if(title) {
        chart.children.unshift(am5.Label.new(chartRoot, {
          text: title,
          fontSize: 14,
          fontWeight: "500",
          textAlign: "left",
          x: 10,
          centerX: 10,
          paddingTop: 0,
          paddingBottom: 0
        }));
      }

      let series = chart.series.push(
        am5percent.PieSeries.new(chartRoot, {
          name: serie.title,
          categoryField: "text",
          valueField: "value",
          colorField: "color",
          fillField: "color",
          legendLabelText: "{category}",
          legendValueText: "{value}",
        })
      );
      series.showOnInit = false;
      series.labels.template.setAll({
        templateField: "settings",
        fontSize: "10px",
      });
      series.labels.template.set("text", "{category}: {valuePercentTotal.formatNumber('#.0')}%");
      series.slices.template.setAll({
        stroke: am5.color(0xffffff),
        strokeWidth: 2,
        tooltipText: "{category}: {value}"
      });

      series.data.setAll(serie.data);
      series.slices.template.adapters.add("fill", function (fill, target) {
        return chart.get("colors").getIndex(series.slices.indexOf(target));
      });

      return () => {
        chartRoot.dispose();
      };
    }
  }, [chartId, serie, width, height, title]);
  return (
    <>
      <div id={chartId} style={{ width: width, height: height }}></div>
    </>
  );
}

export default PieChart;
