import React, { useState, useEffect, useCallback } from "react";
import { Container, Row } from "react-bootstrap";
import "../components/common/tools.css";
import { useLazyQuery } from "@apollo/client";
import { LOAD_COMPANIES_USER_STATS } from "../GraphQL/queries";
import FilterableDataTable from "../components/common/filterableDataTable";
import LoadingComponent from "../components/shared/loadingComponent";
import { useSelector } from "react-redux";

function ParentReportPage() {
  const selectedCompany = useSelector((state) => state.company.company);
  const profileObj = useSelector((state) => state.profile.profile);
  const { user } = profileObj || {};
  const { isAdmin } = user || {};
  const [currentCompanyId, setCurrentCompanyId] = useState(null);
  
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [tableTotal, setTableTotal] = useState({});

  const [loadUsersStatsQuery] = useLazyQuery(LOAD_COMPANIES_USER_STATS);

  const loadUsersStats = useCallback(() => {
    if (selectedCompany && selectedCompany.id && selectedCompany.id !== currentCompanyId) {
      loadUsersStatsQuery({
        fetchPolicy: "no-cache",
        variables: {},
        onCompleted: (data) => {
          if (data) {
            const { companies } = data || [];
            const { edges } = companies || {};

            var ret = [];
            for (const node of Object.values(edges)) {
              const co = node.node;
              // console.log("co:", co.id);
              if (!co.name.startsWith("__")) {
                const obj = {
                  ...(isAdmin && { "Parent company": co.parent?.name }),
                  Name: co.name,
                  _uuid: co.id,
                  "# policy holders": parseInt(co.usersStats.totalUsers),
                  "Total employees": parseInt(co.usersStats.nbPolicyHolders),
                  "# dependents": parseInt(co.usersStats.nbDependents),
                  "# Signed Up": parseInt(co.usersStats.nbSignedUpEmployees),
                  "# Onboarded": parseInt(co.usersStats.nbOnboardedEmployees),
                  "# Offboarded": parseInt(co.usersStats.nbOffboardedEmployees),
                  "Total pts earned": parseInt(co.usersStats.totalPointsEarned),
                  "Total pts redeemed": parseInt(
                    co.usersStats.totalPointsRedeemed
                  ),
                  "Avg pts earned": Math.round(co.usersStats.avgPointsEarned),
                  "Avg pts redeemed": Math.round(
                    co.usersStats.avgPointsRedeemed
                  ),
                };
                ret.push(obj);
              }
            }
            setTableData(ret);
            const row = {
              ...(isAdmin && { "Parent company": "countDistinct" }),
              Name: "count",
              _uuid: null,
              "# policy holders": "sum",
              "Total employees": "sum",
              "# dependents": "sum",
              "# Signed Up": "sum",
              "# Onboarded": "sum",
              "# Offboarded": "sum",
              "Total pts earned": "sum",
              "Total pts redeemed": "sum",
              "Avg pts earned": "avg",
              "Avg pts redeemed": "avg",
            };
            setTableTotal(row);
            setIsLoading(false);
          }
        },
        onError: (err) => {
          setIsLoading(false);
          document.body.style.cursor = "default";
        },
      });
    }
  }, [selectedCompany, isAdmin, currentCompanyId, loadUsersStatsQuery]);

  useEffect(() => {
    if (
      selectedCompany &&
      selectedCompany.id !== currentCompanyId
    ) {
      setCurrentCompanyId(selectedCompany.id);
      setIsLoading(true);
      loadUsersStats();
    }
  }, [
    selectedCompany,
    currentCompanyId,
    isLoading,
    loadUsersStats,
  ]);
  return isLoading || !selectedCompany ? (
    <LoadingComponent />
  ) : (
    <Container>
      <Row>
        <FilterableDataTable
          title="Users"
          data={tableData}
          total={tableTotal}
        />
      </Row>
    </Container>
  );
}

export default ParentReportPage;
