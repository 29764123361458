import React, { useState, useEffect } from "react";
import {
  Container,
  ProgressBar,
  Button,
  Row,
  Col,
  Card,
  ListGroup,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import "../login/loginSSO.css";
import "./billing.css";
import { useMutation } from "@apollo/client";
import {
  CLOSE_ACCOUNT,
  REDIRECT_TO_FIRST_CHECKOUT,
  REDIRECT_TO_CUSTOMER_PORTAL,
  ADD_SEATS,
} from "../../GraphQL/mutations";
import { toast } from "react-toastify";
import LoadingComponent from "../shared/loadingComponent";
import Switch from "react-switch";
import { tr } from "../labels.js";
import { ReactComponent as Lock } from "bootstrap-icons/icons/file-earmark-lock.svg";
import { useSelector } from "react-redux";

function BillingPlans() {
  const profileObj = useSelector((state) => state.profile.profile);
  console.log("profileObj:", profileObj);
  const { company } = profileObj || {};
  const selectedCompany = useSelector((state) => state.company.company);
  console.log("selectedCompany:", selectedCompany);
  const companyPlan = selectedCompany?.plan;
  const companyMaxSeats = selectedCompany?.maxSeats;
  const [isLoading, setIsLoading] = useState(true);
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const [closeAccount] = useMutation(CLOSE_ACCOUNT, {
    fetchPolicy: "no-cache",
  });
  const [addSeats] = useMutation(ADD_SEATS, { fetchPolicy: "no-cache" });
  const [redirectToFirstCheckout] = useMutation(REDIRECT_TO_FIRST_CHECKOUT, {
    fetchPolicy: "no-cache",
  });
  const [redirectToCustomerPortal] = useMutation(REDIRECT_TO_CUSTOMER_PORTAL, {
    fetchPolicy: "no-cache",
  });
  const [showCloseAccount, setShowCloseAccount] = useState(false);
  const [showBuyMoreSeats, setShowBuyMoreSeats] = useState(params.buy);
  const [showNewSubscription, setShowNewSubscription] = useState(false);
  const [newPlan, setNewPlan] = useState();
  const [seats, setSeats] = useState(0);
  const [newSeats, setNewSeats] = useState(1);
  const [yearlyChecked, setYearlyChecked] = useState(true);

  useEffect(() => {
    if (companyPlan) {
      setSeats(companyMaxSeats);
      setIsLoading(false);
    }
  }, [companyPlan, companyMaxSeats]);

  const planName = (plan) => {
    return plans[plan || "TRIAL"].name;
  };

  const planIndex = (plan) => {
    return plans[plan || "STARTER"].index;
  };

  const handleToggle = () => {
    setYearlyChecked(!yearlyChecked);
  };

  const plans = {
    TRIAL: {
      index: 0,
      name: "Trial",
      value: "TRIAL",
      selected: companyPlan === "TRIAL",
      price: 0,
      yearlyPrice: 0,
    },
    STARTER: {
      index: 1,
      name: "Starter",
      value: "STARTER",
      selected: companyPlan === "STARTER",
      price: 10,
      yearlyPrice: 110,
    },
    ADVANCED: {
      index: 2,
      name: tr("advancedLabel"),
      value: "ADVANCED",
      selected: companyPlan === "ADVANCED",
      price: 15,
      yearlyPrice: 165,
    },
    ELITE: {
      index: 3,
      name: "Elite",
      value: "ELITE",
      selected: companyPlan === "ELITE",
      price: 20,
      yearlyPrice: 220,
    },
  };

  const sections = [
    {
      name: tr("rewardsRedemptionLabel"),
      features: [
        {
          name: tr("perMonthLabel"),
          bold: true,
          plans: [undefined, "Up to $7", "Up to $12", "Up to $20"],
        },
      ],
    },
    {
      name: tr("healthLabel"),
      features: [
        {
          name: tr("monthlyHealthScansLabel"),
          bold: true,
          plans: [2, 2, 4, 8],
        },
        { name: tr("riskAnalysisLabel"), plans: [true, true, true, true] },
        { name: tr("measureAnalysisLabel"), plans: [true, true, true, true] },
      ],
    },
    {
      name: tr("analyticsLabel"),
      features: [
        {
          name: tr("populationAnalysisLabel"),
          plans: [true, true, true, true],
        },
        {
          name: tr("engagementAnalysisLabel"),
          plans: [false, true, true, true],
        },
        { name: tr("healthAnalysisLabel"), plans: [false, false, false, true] },
      ],
    },
    {
      name: tr("dietaryAnalysisLabel"),
      features: [
        {
          name: tr("unlimitedManualFoodLogLabel"),
          plans: [true, true, true, true],
        },
        { name: tr("mealScansLabel"), bold: true, plans: [10, 0, 15, 40] },
        {
          name: tr("nutritionalAnalysisLabel"),
          plans: [true, true, true, true],
        },
      ],
    },
    {
      name: tr("challengesLabel"),
      features: [
        {
          name: tr("individualChallengesLabel"),
          plans: [true, true, true, true],
        },
        { name: tr("groupLeaderboardLabel"), plans: [true, true, true, true] },
        { name: tr("onboardingRaffleLabel"), plans: [false, true, true, true] },
        {
          name: tr("departmentLeaderboardLabel"),
          plans: [false, false, true, true],
        },
        { name: tr("bonusPointsLabel"), plans: [false, false, false, true] },
      ],
    },
    {
      name: tr("giftCardsLabel"),
      features: [
        { name: tr("giftCardsVarietyLabel"), plans: [true, true, true, true] },
        { name: tr("countriesCoveredLabel"), plans: [true, true, true, true] },
      ],
    },
    {
      name: tr("fitnessWearablesLabel"),
      features: [
        { name: "Apple Watch / Health", plans: [true, true, true, true] },
        { name: "Google Fit", plans: [true, true, true, true] },
        { name: "Fitbit", plans: [true, true, true, true] },
        { name: "Garmin", plans: [true, true, true, true] },
        { name: "Withings", plans: [true, true, true, true] },
        {
          name: tr("fitnessRecommendationsLabel"),
          plans: [true, true, true, true],
        },
        {
          name: tr("sleepRecommendationsLabel"),
          plans: [true, true, true, true],
        },
      ],
    },
  ];

  // To dump to firebase
  // const dump = Object.values(plans).map((plan, idx) => {
  //   const sects = sections.map((section) => {
  //     const feats = section.features.map((feature) => {
  //       return {
  //         name: feature.name,
  //         bold: feature.bold,
  //         value: feature.plans[idx],
  //       };
  //     });
  //     return { name: section.name, features: feats };
  //   });
  //   return {
  //     index: plan.index,
  //     name: plan.name,
  //     value: plan.value,
  //     monthlyPrice: plan.price,
  //     yearlyPrice: plan.yearlyPrice,
  //     sections: sects,
  //   };
  // });
  // console.log(JSON.stringify(dump))

  const planPrice = (plan) => {
    const pl = plans[plan || "TRIAL"];
    return yearlyChecked ? pl.yearlyPrice : pl.price;
  };

  const duration = () => {
    return selectedCompany.subscriptionInterval === "YEAR"
      ? tr("yearLabel")
      : tr("monthLabel");
  };
  const durationly = () => {
    return selectedCompany.subscriptionInterval === "YEAR"
      ? tr("yearlyLabel")
      : tr("monthlyLabel");
  };

  const showValue = (val, feature) => {
    if (typeof val === "boolean") {
      return val ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#FF902D"
          className="bi bi-check-circle-fill"
          viewBox="0 0 16 16"
        >
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#FF902D"
          className="bi bi-circle"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        </svg>
      );
    } else {
      if (feature["bold"]) {
        return <span style={{ fontWeight: "700" }}>{val}</span>;
      } else {
        return <span>{val}</span>;
      }
    }
  };

  const showSection = (section) => {
    const features = section.features
      .map((feature) =>
        showFeatureIfActivated(
          feature.plans[planIndex(selectedCompany.plan)],
          feature
        )
      )
      .filter((item) => item !== undefined);
    if (features.length > 0) {
      return (
        <>
          <Col>
            <ListGroup.Item className="section">{section.name}</ListGroup.Item>
            {features}
          </Col>
        </>
      );
    }
  };

  const showFeatureIfActivated = (val, feature) => {
    if (val !== undefined) {
      return (
        <ListGroup.Item className="feature">
          {showValue(val, feature)} {feature.name}
        </ListGroup.Item>
      );
    }
  };

  const onRunMutation = (e, method, methodName, variables, onDone) => {
    e.preventDefault();
    document.body.style.cursor = "wait";
    method({
      variables: variables || {},
    })
      .then(({ data }) => {
        document.body.style.cursor = "default";
        if (data[methodName].error) {
          toast.error(data[methodName].error);
        } else {
          if (onDone) {
            onDone(data[methodName]);
          } else {
            toast.success(data[methodName].success);
          }
        }
      })
      .catch((err) => {
        document.body.style.cursor = "default";
        if (methodName in err) {
          toast.error(err[methodName].error);
        } else {
          toast.error(err.message);
        }
      });
  };

  const onCloseAccount = (e) => {
    e.preventDefault();
    setShowCloseAccount(true);
  };

  const onBuyMoreSeats = (e) => {
    e.preventDefault();
    setNewSeats(1);
    setShowBuyMoreSeats(true);
  };

  const onChangePlan = (e) => {
    e.preventDefault();
    const val = e.target.getAttribute("value");
    setNewPlan(val);
    // TODO : manage the first time and second time
    setShowNewSubscription(true);
  };

  const handleCancel = () => {
    window.history.pushState(null, "Billing", "/account-billing");
    setShowCloseAccount(false);
    setShowBuyMoreSeats(false);
    setShowNewSubscription(false);
  };

  const onValidateCloseAccount = (e) => {
    handleCancel();
    onRunMutation(
      e,
      closeAccount,
      "closeAccount",
      { companyId: company.id },
      () => {
        localStorage.removeItem("token");
        window.location.href = "/";
      }
    );
  };

  const onValidateMoreSeats = (e) => {
    handleCancel();
    onRunMutation(
      e,
      addSeats,
      "addSeats",
      { companyId: company.id, seats: newSeats },
      () => {
        window.location.reload();
      }
    );
  };

  const onValidateChangePlan = (e) => {
    handleCancel();
    onRunMutation(
      e,
      redirectToFirstCheckout,
      "redirectToFirstCheckout",
      {
        companyId: company.id,
        plan: newPlan,
        seats: seats,
        yearly: yearlyChecked,
      },
      (data) => {
        console.log(data);
        console.log(data.url);
        if (data && data.url) {
          window.location.href = data.url;
        }
      }
    );
  };
  const onManageBilling = (e) => {
    handleCancel();
    onRunMutation(
      e,
      redirectToCustomerPortal,
      "redirectToCustomerPortal",
      { companyId: company.id },
      (data) => {
        if (data && data.url) {
          window.location.href = data.url;
        }
      }
    );
  };
  return isLoading || !selectedCompany ? (
    <LoadingComponent />
  ) : companyPlan === "CONTRACT" ? (
    <>
      <div style={{ paddingTop: "20%" }}>
        <h1>You need access</h1>
        <h2>Request access, or switch account with access</h2>
        <hr style={{ color: "transparent" }} />
        <Lock style={{ width: "128px", height: "128px", color: "#FF8C00" }} />
      </div>
    </>
  ) : companyPlan === "TRIAL" ? (
    <>
      <div className="billing-title">{tr("selectPreferredPlanLabel")}</div>
      <div className="billing-subtitle">
        {tr("getStartedCancelAnytimeLabel")}
      </div>

      <Container fluid="md">
        <Row className="justify-content-center">
          <Col xs lg="2">
            <div className="check-container">
              <span className={yearlyChecked ? "check" : "check selected"}>
                {tr("monthlyLabel")}
              </span>
              <span className="switch">
                <Switch
                  onChange={handleToggle}
                  checked={yearlyChecked}
                  height={20}
                  width={50}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  handleDiameter={18}
                />
              </span>
              <span className={yearlyChecked ? "check selected" : "check"}>
                {" "}
                {tr("yearlyLabel")}
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          {Object.values(plans).map((pl, idx) => (
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title className="plan-title">{pl.name}</Card.Title>
                  <div className="price">
                    {pl.price === 0 ? (
                      <>
                        <div className="plan-price">{tr("freeLabel")}</div>
                        <br />
                      </>
                    ) : (
                      <>
                        <div className="plan-price">
                          ${yearlyChecked ? pl.yearlyPrice : pl.price}
                        </div>
                        <div className="plan-price-desc">
                          / {tr("seatLabel")} /{" "}
                          {yearlyChecked ? tr("yearLabel") : tr("monthLabel")}
                        </div>
                      </>
                    )}
                  </div>
                  {pl.value === "TRIAL" ? (
                    <Button variant="primary main-inverse">
                      {companyMaxSeats} {tr("seatActiveLabel")}
                    </Button>
                  ) : (
                    <Button
                      variant="primary main"
                      value={pl.value}
                      onClick={onChangePlan}
                    >
                      {tr("subscribeLabel")}
                    </Button>
                  )}
                  {sections.map((section, section_idx) => (
                    <>
                      <div className="section">{section.name}</div>
                      {section.features.map((feature, feature_idx) => (
                        <div className="feature">
                          {feature.plans[idx] === undefined ? (
                            <div>&nbsp;</div>
                          ) : (
                            <div>
                              {showValue(feature.plans[idx], feature)}{" "}
                              {feature.name}
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            {companyPlan === "TRIAL" ? (
              <div className="very-small">
                {tr("closeAccountInfoLabel")}{" "}
                <a href="#home" onClick={onCloseAccount}>
                  {tr("linkLabel")}
                </a>
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Container>

      <Modal show={showCloseAccount} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{tr("closeAccountLabel")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tr("closingAccountWarningLabel")}.
          <br />
          {tr("noAccessAfterClosingLabel")}.
          <br />
          {tr("signedOutAfterClosingLabel")}
          <br />
          {tr("irreversibleActionLabel")}.
          <br />
          {tr("areYouSureLabel")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            {tr("cancelLabel")}
          </Button>
          <Button variant="danger" onClick={onValidateCloseAccount}>
            {tr("yesCloseAccountLabel")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showNewSubscription} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>
            {tr("subplanLabel")} '{planName(newPlan)}'
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tr("howmanyLabel")} ${planPrice(newPlan)}?<br />
          <form className="Login-Form">
            <div className="check-container">
              <span className={yearlyChecked ? "check" : "check selected"}>
                {tr("monthlyLabel")}
              </span>
              <span className="switch">
                <Switch
                  onChange={handleToggle}
                  checked={yearlyChecked}
                  height={20}
                  width={50}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  handleDiameter={18}
                />
              </span>
              <span className={yearlyChecked ? "check selected" : "check"}>
                {" "}
                {tr("yearlyLabel")}
              </span>
            </div>
            <div>
              <label className="form-label form-span" htmlFor="seat">
                {tr("SeatsLabel")} ({tr("mininumLabel")}: {companyMaxSeats}{" "}
                {tr("seatLabel")})
              </label>
              <input
                className="form-control form-span"
                type="number"
                id="seats"
                name="seats"
                min={companyMaxSeats}
                value={seats}
                onChange={(e) => {
                  setSeats(e.target.value);
                }}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            {tr("cancelLabel")}
          </Button>
          <Button variant="success" onClick={onValidateChangePlan}>
            {tr("buyLabel")} {seats} '{planName(newPlan)}' {tr("seatsforLabel")}{" "}
            ${seats * planPrice(newPlan)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <>
      <Container fluid="md">
        <Row>
          <Col sm="4">
            <Card>
              <Card.Body>
                <Card.Title>
                  {tr("currentLabel")} {durationly()} {tr("billLabel")}
                </Card.Title>
                <div className="card-content-100">
                  <Card.Text className="current-bill">
                    ${selectedCompany.subscriptionTotalPriceUsd}
                  </Card.Text>
                </div>
                <Card.Link href="#">
                  <Button variant="primary main" onClick={onManageBilling}>
                    {tr("manageLabel")}
                  </Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="4">
            <Card>
              <Card.Body>
                <Card.Title>{tr("SeatsLabel")}</Card.Title>
                <div className="card-content-100">
                  <ListGroup className="seats-list" variant="flush">
                    <ProgressBar
                      now={
                        (100 * selectedCompany.nbInvitations) /
                        selectedCompany.maxSeats
                      }
                    />
                    <ListGroup.Item className="nb-seats-used">
                      {selectedCompany.nbInvitations} {tr("ofLabel")}{" "}
                      {selectedCompany.maxSeats} {tr("usedLabel")}
                    </ListGroup.Item>
                    <ListGroup.Item className="total-price">
                      ${selectedCompany.subscriptionPerSeatPriceUsd} /{" "}
                      {tr("seatLabel")} / {duration()}
                    </ListGroup.Item>
                  </ListGroup>
                </div>
                <Card.Link href="#">
                  <Button variant="primary main" onClick={onBuyMoreSeats}>
                    {tr("buyMoreSeatsLabel")}
                  </Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="4">
            <Card>
              <Card.Body>
                <Card.Title>
                  {selectedCompany.subscriptionCancelAt
                    ? tr("willbecancelLabel")
                    : tr("nextpaymentLabel")}
                </Card.Title>
                <div className="card-content-100">
                  <Card.Text className="payment-due-date">
                    {moment(
                      selectedCompany.subscriptionCancelAt ||
                        selectedCompany.subscriptionRenewAt
                    ).format("MMMM D, YYYY")}
                  </Card.Text>
                </div>
                <Card.Link href="#">
                  <Button variant="primary main" onClick={onManageBilling}>
                    {tr("viewpaymenthistoryLabel")}
                  </Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="current-plan-title">{tr("currentplanLabel")}</div>
        <hr />
        <Card className="sections">
          <Card.Header>{planName(selectedCompany.plan)}</Card.Header>
          <Card.Body>
            <Row xs={1} md={3}>
              {sections.map((section) => showSection(section))}
            </Row>
          </Card.Body>
        </Card>
      </Container>
      <Modal show={showBuyMoreSeats} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{tr("buyMoreSeatsLabel")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="Login-Form">
            <div>
              <input
                className="form-control form-span"
                type="number"
                id="seats"
                name="seats"
                min={0}
                value={newSeats}
                onChange={(e) => {
                  setNewSeats(e.target.value);
                }}
              />
              <span>
                {" "}
                {tr("newseatLabel")}
                {newSeats > 1 ? "s" : ""} @ $
                {selectedCompany.subscriptionPerSeatPriceUsd}/{duration()}{" "}
                {tr("eachLabel")}, {tr("proratedLabel")}
              </span>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            {tr("cancelLabel")}
          </Button>
          <Button
            variant="success"
            onClick={onValidateMoreSeats}
            style={{ minWidth: "200px" }}
          >
            {tr("AddLabel")} {newSeats} {tr("seatLabel")}
            {newSeats > 1 ? "s" : ""} {tr("forLabel")} $
            {newSeats * selectedCompany.subscriptionPerSeatPriceUsd}
          </Button>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-credit-card"
              viewBox="0 0 16 16"
            >
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z" />
              <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
            </svg>{" "}
            {tr("chargetoyourcardLabel")}
          </span>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BillingPlans;
