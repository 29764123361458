import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Container, Row, Col, Card, Table } from 'react-bootstrap'
import { useLazyQuery } from '@apollo/client'
import PieChart from '../components/shared/pieChartComponent'
import LoadingComponent from '../components/shared/loadingComponent'
import { LOAD_COMPANY_ANALYTICS } from '../GraphQL/queries'
import constants from '../components/shared/constants'
import { ReactComponent as ArrowDownIcon } from 'bootstrap-icons/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from 'bootstrap-icons/icons/arrow-up.svg'
import { ReactComponent as BarChartFillIcon } from 'bootstrap-icons/icons/bar-chart-fill.svg'
import ScrollToTop from '../components/ScrollToTop/ScrollToTop'
import VitalMeasuresIcon from '../icons_analytics/healthcare.svg'
import MindfullnessIcon from '../icons_analytics/mindfullness.svg'
import SleepMeasureIcon from '../icons_analytics/Sleeping.svg'
import AppearanceMeasureIcon from '../icons_analytics/male.svg'
import MainRiskIcon from '../icons_analytics/risk.svg'
import FitnessMeasureIcon from '../icons_analytics/Running.svg'
import HealthMeasureIcon from '../icons_analytics/HealthMeasure.svg'
import { handleMutlipleExport, handleSingleExport } from '../components/utils'
import { ReactComponent as DownloadIcon } from 'bootstrap-icons/icons/download.svg'
import { useSelector } from 'react-redux'
import InfoIcon from '../icons_analytics/info.svg'
import { Tooltip } from 'react-tooltip'

const colorMap = {
  dark_green: '#9DCFB3',
  green: '#B5E4CA',
  yellow: '#FFDD96',
  light_red: '#E89898',
  red: '#EE7878',
}

function GaugeStepsTable({ gaugeSteps, unit }) {
  const formatValue = (value) => {
    if (value !== undefined && value !== null) {
      if (unit === 'hours') {
        return `${(value / 60).toFixed(1)} ${unit}`
      } else {
        const formattedValue = Number(value).toLocaleString('en-US')
        return `${formattedValue} ${unit}`
      }
    } else {
      return ''
    }
  }

  return (
    <Table bordered className="custom-table">
      <thead>
        <tr>
          {gaugeSteps.map((step, index) => (
            <th key={index} style={{ backgroundColor: colorMap[step.color] }}>
              {step.status.replace(/\s/g, '\u00A0')}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {gaugeSteps.map((step, index) => (
            <td key={index} style={{ backgroundColor: colorMap[step.color] }}>
              <span
                className={`min ${
                  index > 0 && step.min === gaugeSteps[index - 1].max
                    ? 'hidden'
                    : ''
                }`}
              >
                {formatValue(step.min)}
              </span>
              <span className="max">{formatValue(step.max)}</span>
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  )
}

function HealthPage() {
  const selectedCompany = useSelector((state) => state.company.company)
  const [analytics, setAnalytics] = useState([])
  const [currentCompanyId, setCurrentCompanyId] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const [loadAnalyticsQuery] = useLazyQuery(LOAD_COMPANY_ANALYTICS)

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    }
    return date.toLocaleString('en-US', options)
  }

  const loadAnalytics = useCallback(() => {
    if (
      selectedCompany &&
      selectedCompany.id &&
      selectedCompany.id !== currentCompanyId
    ) {
      loadAnalyticsQuery({
        fetchPolicy: 'no-cache',
        variables: { id: selectedCompany.id },
        onCompleted: (data) => {
          if (data) {
            // console.log("loadAnalyticsQuery", data);
            const updatedAnalytics = data.company.analytics.map((analytic) => {
              if (!analytic.showZeros) {
                return {
                  ...analytic,
                  series: analytic.series.filter((serie) => serie.value !== 0),
                }
              }
              return analytic
            })

            setAnalytics(updatedAnalytics)
            console.log(updatedAnalytics)
            setIsLoading(false)
            setCurrentCompanyId(selectedCompany.id)
          }
        },
      })
    }
  }, [
    selectedCompany,
    loadAnalyticsQuery,
    currentCompanyId,
    setCurrentCompanyId,
  ])

  useEffect(() => {
    loadAnalytics()
  }, [currentCompanyId, loadAnalytics])

  const format_val = (raw_value, nbDecimals, unit) => {
    if (raw_value === null || raw_value === undefined) {
      return null
    }
    const val = Number(raw_value.toFixed(nbDecimals || 0)).toLocaleString()
    let ret = val
    if (unit) {
      ret = `${val} ${unit}`
    }
    // console.log(raw_value, ret)
    return ret
  }

  const convert_to_serie = (data) => {
    const ret = {
      title: data.text,

      data: data.series.map((row) => {
        return {
          ...row,
          nbDecimals: data.nbDecimals,
          row_color: row.color,
          color: constants.COLORS[row.color],
        }
      }),
    }
    // console.log(ret)
    return ret
  }

  const groupBySection = (analytics) => {
    return analytics
      .sort((a, b) => a.order - b.order)
      .reduce((acc, cur) => {
        if (!acc[cur.section]) {
          acc[cur.section] = []
        }
        acc[cur.section].push(cur)
        return acc
      }, {})
  }

  const groupedAnalytics = groupBySection(analytics)
  const sectionRefs = useRef({})

  const handleSectionClick = (e, section) => {
    e.preventDefault()
    if (sectionRefs.current[section]) {
      sectionRefs.current[section].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  const sections = Object.keys(groupedAnalytics)

  function getSectionIcon(section) {
    switch (section) {
      case 'Appearance Measures':
        return AppearanceMeasureIcon
      case 'Vital Measures':
        return VitalMeasuresIcon
      case 'Main Risk Areas':
        return MainRiskIcon
      case 'Health Measures':
        return HealthMeasureIcon
      case 'Fitness Measures':
        return FitnessMeasureIcon
      case 'Mindfulness Measures':
        return MindfullnessIcon
      case 'Sleep Measures':
        return SleepMeasureIcon
      default:
        return null
    }
  }

  const isFakeInAnalytics = () => {
    return analytics.some((analytic) => analytic.isFake)
  }
  const baseUrl = process.env.REACT_APP_API_URL.replace(
    '/graphql',
    '/csv_analytics'
  )
  const link = `${baseUrl}/${selectedCompany.id}`

  return isLoading || !selectedCompany ? (
    <LoadingComponent />
  ) : (
    <Container fluid="md" className="home">
      <Row className="justify-content-center align-items-start">
        <Col>
          <div className="section-links">
            {sections
              .sort((a, b) => a.order - b.order)
              .map((section) => (
                <button
                  key={section}
                  onClick={(e) => handleSectionClick(e, section)}
                  className="section-link"
                  type="button"
                >
                  <div className="circle">
                    <img
                      src={getSectionIcon(section)}
                      className="Icon"
                      alt="section"
                    />
                  </div>
                  <br />
                  {section}
                </button>
              ))}
          </div>
          {isFakeInAnalytics() ? (
            <Card className="is-fake-indicator">
              ⚠️ Sample data shown. Real data available when employee count
              exceeds 25.
            </Card>
          ) : (
            <Row style={{ textAlign: 'right', paddingRight: '10px' }}>
              <a href={link}>Download all data</a>
            </Row>
          )}
        </Col>
      </Row>
      {sections
        .sort((a, b) => a.order - b.order)
        .map((section, idx_section) => (
          <div
            key={section}
            id={`section-${idx_section}`}
            ref={(el) => (sectionRefs.current[section] = el)}
          >
            <Row
              xs={1}
              md={1}
              className="justify-content-center align-items-start"
            >
              <Card className="card-section">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <img
                    src={getSectionIcon(section)}
                    className="icon-section-title"
                    alt={section}
                  />
                  <h3 className="section-title">{section}</h3>
                  <button
                    className="btn"
                    onClick={() =>
                      handleMutlipleExport(groupedAnalytics[section], section)
                    }
                    style={{ padding: '2px 5px' }}
                  >
                    <DownloadIcon />
                  </button>
                </div>
                {groupedAnalytics[section].map((measure, idx_measure) => (
                  <div key={idx_measure}>
                    <Container className="bg-white">
                      <Row
                        xs={1}
                        md={1}
                        className="justify-content-center align-items-start"
                      ></Row>
                      <Col className="col-title">
                        <div className="measure-title">
                          <img alt={measure.icon} src={measure.iconUrl} />{' '}
                          <button
                            className="btn export-buttons"
                            onClick={() => handleSingleExport(measure)}
                            style={{ padding: '2px 5px' }}
                          >
                            <DownloadIcon />
                          </button>
                          {measure.text}
                          <a
                            data-tooltip-id={`tooltip-measure-description-${idx_section}-${idx_measure}`}
                            data-tooltip-html={`<div style='font-weight: normal;'>${measure.tooltip}</div>`}
                          >
                            <img
                              src={InfoIcon}
                              alt="info"
                              style={{
                                height: '2%',
                                width: '2%',
                                left: '1%',
                                position: 'relative',
                              }}
                            />
                          </a>
                          <Tooltip
                            id={`tooltip-measure-description-${idx_section}-${idx_measure}`}
                            opacity="1"
                            border="1px solid grey"
                            style={{
                              backgroundColor: 'rgb(250,250,250)',
                              color: '#222',
                              maxWidth: '500px',
                              whiteSpace: 'normal',
                              fontSize: '0.8rem',
                              lineHeight: '1rem',
                              textAlign: 'left',
                            }}
                          />
                        </div>
                      </Col>
                      <Row
                        xs={1}
                        md={2}
                        className="g-4 main-steps justify-content-center align-items-start"
                      >
                        <Col>
                          <Card className="min-height-150">
                            {!measure.isReady ? (
                              <LoadingComponent />
                            ) : measure.nbMeasures === 0 ? (
                              <Card.Body>
                                <img
                                  alt={`no ${measure.dimensionText}`}
                                  className="nothing"
                                  src="https://cdn.healthmov.com/app_icons/nothing.png"
                                />
                                <p>No {measure.dimensionText} found !</p>
                              </Card.Body>
                            ) : (
                              <Card.Body>
                                <div className="measure-info">
                                  <span className="strong">
                                    Average value
                                    <a
                                      data-tooltip-id={`tooltip-measure-legend-${idx_section}-${idx_measure}`}
                                      data-tooltip-html="<strong>Min:</strong> Average value for each users’ minimum recorded value.<br /><strong>Overall:</strong> Average of all recorded values in this group.<br /><strong>Latest:</strong> Average value for each users’ latest recorded value.<br /><strong>Max:</strong> Average value for each users’ maximum recorded value."
                                      style={{
                                        left: '30%',
                                        position: 'relative',
                                      }}
                                    >
                                      <img
                                        src={InfoIcon}
                                        alt="info"
                                        style={{
                                          height: '5%',
                                          width: '5%',
                                        }}
                                      />
                                    </a>
                                    <Tooltip
                                      id={`tooltip-measure-legend-${idx_section}-${idx_measure}`}
                                      opacity="1"
                                      border="1px solid grey"
                                      style={{
                                        backgroundColor: 'rgb(250,250,250)',
                                        color: '#222',
                                        maxWidth: '500px',
                                        whiteSpace: 'normal',
                                        fontSize: '0.8rem',
                                        lineHeight: '1rem',
                                        textAlign: 'left',
                                        zIndex: '1000',
                                      }}
                                    />
                                    <br />
                                    {format_val(measure.nbMeasures, 0)}{' '}
                                    {measure.dimensionText} from{' '}
                                    {format_val(measure.nbUsers, 0)} users
                                  </span>
                                </div>
                                {measure.sum ? (
                                  <div className="measure-info">
                                    <span className="strong">Total = </span>
                                    <span className="value">
                                      {format_val(
                                        measure.sum,
                                        measure.nbDecimals,
                                        measure.unit
                                      )}
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {measure.min && measure.max ? (
                                  <Table bordered hover={false}>
                                    <thead>
                                      <tr>
                                        <th>
                                          <ArrowDownIcon /> Min
                                        </th>
                                        <th>
                                          <BarChartFillIcon /> Overall
                                        </th>
                                        <th>
                                          <BarChartFillIcon /> Latest
                                        </th>
                                        <th>
                                          <ArrowUpIcon /> Max
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {format_val(
                                            measure.min,
                                            measure.nbDecimals,
                                            measure.unit
                                          )}
                                        </td>
                                        <td>
                                          {format_val(
                                            measure.average,
                                            measure.nbDecimals,
                                            measure.unit
                                          )}
                                        </td>
                                        <td>
                                          {format_val(
                                            measure.current,
                                            measure.nbDecimals,
                                            measure.unit
                                          )}
                                        </td>
                                        <td>
                                          {format_val(
                                            measure.max,
                                            measure.nbDecimals,
                                            measure.unit
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                ) : (
                                  <></>
                                )}
                                <GaugeStepsTable
                                  gaugeSteps={measure.gaugeSteps}
                                  unit={measure.unit}
                                />
                              </Card.Body>
                            )}
                          </Card>
                        </Col>
                        <Col>
                          <Card className="min-height-150">
                            {!measure.isReady ? (
                              <LoadingComponent />
                            ) : measure.nbMeasures === 0 ? (
                              <Card.Body>
                                <img
                                  alt={`no ${measure.dimensionText}`}
                                  className="nothing"
                                  src="https://cdn.healthmov.com/app_icons/nothing.png"
                                />
                                <p>No {measure.dimensionText} found !</p>
                              </Card.Body>
                            ) : (
                              <Card.Body>
                                <PieChart
                                  title="Overall"
                                  chartId={measure.dimension}
                                  serie={convert_to_serie(measure)}
                                  width={'100%'}
                                  height={155}
                                />
                              </Card.Body>
                            )}
                          </Card>
                        </Col>
                      </Row>
                      <span className="footer-card">
                        Data updated as on : {formatDate(measure.updatedAt)}
                      </span>
                    </Container>
                  </div>
                ))}
              </Card>
            </Row>
          </div>
        ))}
      <ScrollToTop />
    </Container>
  )
}

export default HealthPage
