import React, { useState, useEffect } from "react";
import "./DownloadComponent.css";
import {
  GooglePlayButton,
  AppStoreButton,
  AppGalleryButton,
  ButtonsContainer,
} from "react-mobile-app-button";
import QRCode from "qrcode.react";
import { tr } from "../labels";

function DownloadComponent({ message }) {
  const urlPhone =
    "./images/It-pays-to-be-Healthy-phones.png";
  const link =
    "https://r.healthmov.com/stores";

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const direction = screenWidth < 750 ? "column" : "row";

  return (
    <div className="download-container">
      <h3>
        {message} {tr("downloadAppLabel")}
      </h3>
      <h2>
        {tr("paysLabel")}{" "}
        <span className="orange-text">{tr("healthyLabl")}</span>
      </h2>
      <br></br>
      <img className="phone-image" src={urlPhone} alt="Mobile phones" />
      <ButtonsContainer direction={direction} gap={40}>
        <div className="qr-button-wrapper">
          <AppStoreButton
            url={link}
            theme={"light"}
            className={"custom-style"}
          />
        </div>
        <div className="qr-button-wrapper">
          <GooglePlayButton
            url={link}
            theme={"light"}
            className={"custom-style"}
          />
        </div>
        <div className="qr-button-wrapper">
          <AppGalleryButton
            url={link}
            theme={"light"}
            className={"custom-style"}
          />
        </div>
      </ButtonsContainer>
      <br></br>
      <QRCode value={link} size={150} />
    </div>
  );
}

export default DownloadComponent;
