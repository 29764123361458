import { gql } from '@apollo/client'

export const LOAD_COMPANIES = gql`
  query loadCompanies {
    companies {
      edges {
        node {
          parent {
            name
          }
          id
          uuid
          name
          plan
          maxSeats
          requestedPlan
          onboardedAt
          subscriptionStatus
          subscriptionTotalPriceUsd
          subscriptionPerSeatPriceUsd
          subscriptionStartedAt
          subscriptionRenewAt
          subscriptionInterval
          subscriptionCancelAt
        }
      }
    }
  }
`

export const LOAD_COMPANY_WITH_INVITATIONS = gql`
  query loadCompanyWithInvitations($id: ID!) {
    company(id: $id) {
      id
      uuid
      name
      plan
      maxSeats
      requestedPlan
      onboardedAt
      subscriptionStatus
      subscriptionTotalPriceUsd
      subscriptionPerSeatPriceUsd
      subscriptionStartedAt
      subscriptionRenewAt
      subscriptionInterval
      subscriptionCancelAt
      nbInvitations
      nbAcceptedInvitations
      nbPendingInvitations
      nbOnboardedInvitations
      nbOffboardedInvitations
      nbSignedUpInvitations
      referralCode {
        code
      }
    }
  }
`

export const LOAD_COMPANY_ANALYTICS = gql`
  query loadCompanyAnalytics($id: ID!) {
    company(id: $id) {
      id
      uuid
      name
      analytics {
        isFake
        section
        order
        showZeros
        barChart
        updatedAt
        dimension
        dimensionText
        isReady
        icon
        iconUrl
        text
        nbMeasures
        nbUsers
        average
        max
        min
        sum
        current
        nbDecimals
        unit
        tooltip
        series {
          text
          value
          color
        }
        gaugeSteps {
          index
          min
          max
          color
          status
        }
      }
    }
  }
`

export const LOAD_COMPANIES_USER_STATS = gql`
  query companiesStats {
    companies {
      edges {
        node {
          id
          name
          parent {
            name
          }
          usersStats {
            isReady
            totalUsers
            nbPolicyHolders
            nbDependents
            nbOnboardedEmployees
            nbOffboardedEmployees
            nbSignedUpEmployees
            totalPointsEarned
            totalPointsRedeemed
            avgPointsEarned
            avgPointsRedeemed
          }
        }
      }
    }
  }
`

export const LOAD_COMPANY_STATS = gql`
  query loadCompanyStats($id: ID!) {
    company(id: $id) {
      id
      uuid
      name
      stats {
        showZeros
        isFake
        section
        order
        updatedAt
        dimension
        dimensionText
        barChart
        isReady
        icon
        iconUrl
        text
        nbMeasures
        nbUsers
        average
        max
        min
        sum
        current
        nbDecimals
        unit
        series {
          text
          value
          color
        }
      }
    }
  }
`

export const LOAD_USER_INFO = gql`
  query {
    me {
      firstName
      lastName
      fullName
      dateOfOnboarding
      pictureUrl
      employeeNumber
      phoneNumber
      company {
        id
        name
        plan
        onboardedAt
      }
      department {
        id
        name
      }
      user {
        isAdmin
        isUserManager
        canUsePortal
        email
        userRoles {
          role
        }
      }
    }
  }
`

export const LOAD_INVITATIONS = gql`
  query invitations($companyId: ID!) {
    invitations(companyId: $companyId) {
      id
      email
      firstName
      lastName
      uuid
      invitedAt: createdAt
      acceptedAt
      user {
        pictureUrl
        email
        employee {
          firstName
          lastName
          fullName
          birthdate
          parentId
          externalId
          onboardedAt
          signedUpAt
          dateOfOnboarding
          earnedFirstPointsAt
          redeemedFirstPointsAt
          lastViewHomeAt
          dateOfOffboarding
        }
      }
    }
  }
`

export const LOAD_DEPENDENTS = gql`
  query dependentInfos($companyId: ID!) {
    dependentInfos(companyId: $companyId) {
      id
      fullName
      firstName
      lastName
      birthdate
      dateOfOnboarding
      dateOfOffboarding
      employee {
        id
        firstName
        lastName
      }
      dependentEmployee {
        id
        user {
          email
        }
      }
      externalId
    }
  }
`

export const GET_INVITATION = gql`
  query getInvitation($uuid: String!) {
    getInvitation(uuid: $uuid) {
      phoneNumber
      acceptedAt
      canUseApp
      email
    }
  }
`

export const GET_CHALLENGE_LEADERBOARD = gql`
  query challengeFromTitle($companyId: ID!, $title: String!) {
    challengeFromTitle(companyId: $companyId, title: $title) {
      id
      title
      unit
      leaderboard {
        players {
          edges {
            node {
              id
              ranking
              startedAt
              value
              target
              progression
              employee {
                id
                fullName
                company {
                  id
                  name
                }
                user {
                  id
                  email
                }
                department {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
