import { createSlice } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
  },
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setProfileUrl: (state, action) => {
        state.profile = { ...state.profile, pictureUrl: action.payload}
    }
  },
});

export const { setProfile, setProfileUrl } = profileSlice.actions;

export default profileSlice.reducer;
