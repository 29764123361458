import React, { useLayoutEffect } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
// import constants from "./constants";

function HorizontalBarChartComponent({
  chartId,
  serie,
  width,
  height,
  legendWidth,
  legendHeight,
  onClick,
}) {
  useLayoutEffect(() => {
    let root = am5.Root.new(chartId)

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)])

    // console.log(serie.data);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
      })
    )

    // We don't want zoom-out button to appear while animating, so we hide it
    chart.zoomOutButton.set('forceHidden', true)

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 15,
    })

    yRenderer.grid.template.set('location', 1)

    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: 'text',
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, { themeTags: ['axis'] }),
      })
    )

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        extraMax: 0.1,
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 0.1,
        }),
      })
    )

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: serie.title,
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: 'value',
        categoryYField: 'text',
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'left',
          labelText: '{valueX}',
        }),
      })
    )
    series.columns.template.events.on('click', function (ev) {
      onClick?.(ev.target.dataItem.dataContext)
    })
    series.showOnInit = false

    // Rounded corners for columns
    series.columns.template.setAll({
      cornerRadiusTR: 5,
      cornerRadiusBR: 5,
      strokeOpacity: 0,
    })

    // Make each column to be of a different color
    series.columns.template.adapters.add('fill', function (fill, target) {
      return chart.get('colors').getIndex(series.columns.indexOf(target))
    })

    series.columns.template.adapters.add('stroke', function (stroke, target) {
      return chart.get('colors').getIndex(series.columns.indexOf(target))
    })

    yAxis.data.setAll(serie.data)
    series.data.setAll(serie.data)

    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none',
        xAxis: xAxis,
        yAxis: yAxis,
      })
    )

    return () => {
      root.dispose()
    }
  }, [chartId, serie, width, height, legendWidth, legendHeight, onClick])
  return (
    <>
      <div id={chartId} style={{ width: width, height: height }}></div>
      <div
        id={`${chartId}-legend`}
        style={{ width: legendWidth, height: legendHeight }}
      ></div>
    </>
  )
}

export default HorizontalBarChartComponent
