import React, { useState, useEffect } from 'react'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'
import { Col, Modal } from 'react-bootstrap'
import './FilterableDataTable.css'
import { darken, lighten, styled } from '@mui/material/styles'

const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5)

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4)

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--info': {
    backgroundColor: getBackgroundColor(
      theme.palette.info.main,
      theme.palette.mode
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--success': {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--warning': {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode
        ),
      },
    },
  },
  '& .super-app-theme--error': {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
      },
    },
  },
}))

function CustomToolbar({ onDelete, disabled }) {
  const showDeleteButton = !disabled
  const urlParts = window.location.pathname.split('/')
  const now = new Date()
  const timestamp = `${now.getFullYear()}-${(now.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}-${now
    .getHours()
    .toString()
    .padStart(2, '0')}-${now.getMinutes().toString().padStart(2, '0')}`
  const fileName = `${urlParts[urlParts.length - 1]}-${timestamp}`

  return (
    <GridToolbarContainer>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          backgroundColor: '#F5F5F5',
        }}
      >
        <div>
          {showDeleteButton && (
            <Button
              color="primary"
              onClick={onDelete}
              variant="contained"
              style={{
                backgroundColor: '#FF902D',
                color: 'white',
                height: '30.75px',
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          )}
        </div>
        <div className="toolbar-actions">
          <GridToolbarExport
            className="toolbar-export"
            csvOptions={{ fileName: fileName }}
          />
          <GridToolbarDensitySelector className="toolbar-density" />
        </div>
      </div>
    </GridToolbarContainer>
  )
}

function SummaryDataGrid({ data, total }) {
  const [summaryRows, setSummaryRows] = useState([])
  const [summaryColumns, setSummaryColumns] = useState([])

  useEffect(() => {
    if (total && data.length > 0) {
      const dataArray = data.reduce((acc, obj) => {
        Object.keys(obj)
          .filter((key) => !key.startsWith('_'))
          .forEach((key) => {
            if (!acc[key]) {
              acc[key] = [obj[key]]
            } else {
              acc[key].push(obj[key])
            }
          })
        return acc
      }, {})

      const result = {}
      Object.entries(total).forEach(([colName, aggregator]) => {
        if (aggregator === 'sum') {
          result[colName] = dataArray[colName].reduce((a, b) => a + b, 0)
        } else if (aggregator === 'avg') {
          let length = dataArray[colName].length
          result[colName] =
            dataArray[colName].reduce((a, b) => a + b, 0) / length
          result[colName] = Math.round(result[colName] * 10) / 10
        } else if (aggregator === 'count') {
          result[colName] = dataArray[colName].filter(
            (value) => value !== null && value !== undefined
          ).length
        } else if (aggregator === 'countDistinct') {
          const uniqueValues = new Set(
            dataArray[colName].filter(
              (value) => value !== null && value !== undefined
            )
          )
          result[colName] = uniqueValues.size
        } else {
          result[colName] = null
        }
      })

      const summaryColumns = Object.keys(result)
        .filter((colName) => !colName.startsWith('_'))
        .map((colName) => ({
          field: colName,
          headerName: colName,
          flex: 1,
          sortable: false,
          align: 'right',
          valueFormatter: ({ value }) => {
            if (typeof value === 'number') {
              return value.toLocaleString()
            }
            return value
          },
        }))

      const summaryRows = [{ id: 'result', ...result }]

      setSummaryColumns(summaryColumns)
      setSummaryRows(summaryRows)
    }
  }, [total, data])

  return (
    <div className="datagrid-wrapper">
      <DataGrid
        autoHeight
        rows={summaryRows}
        columns={summaryColumns}
        rowHeight={30}
        headerHeight={40}
        hideFooter
        className="datagrid"
        disableColumnFilter
        disableColumnMenu
      />
    </div>
  )
}

function FilterableDataTable({ data, total, onDelete, selectableRows }) {
  const [selectedRows, setSelectedRows] = useState([])
  const [currentRows, setCurrentRows] = useState(data)
  const [columns, setColumns] = useState([])
  const [showModal, setShowModal] = useState(false)
  if (data[0] && !data[0]._uuid) {
    for (let i = 0; i < data.length; i++) {
      data[i]._uuid = i
    }
  }

  useEffect(() => {
    if (data.length > 0) {
      const columnsFromData = Object.keys(data[0])
        .filter((key) => !key.startsWith('_'))
        .map((key) => {
          const column = {
            field: key,
            headerName: key,
            flex: key === 'Id' ? 0.5 : 1,
            renderCell: ({ value, colDef }) => {
              const formattedValue =
                colDef.valueFormatter && colDef.valueFormatter({ value })
              return value === null || value === ''
                ? 'NA'
                : formattedValue || value
            },
          }
          const columnData = data
            .map((row) => row[key])
            .filter((d) => d !== null)
          const hasDateValues =
            key !== 'Id' &&
            (key.endsWith(' At') ||
              columnData.some((value) => {
                const regex = /^\d{4}-\d{2}-\d{2}$/

                // Check if the string matches the regex
                return value.toString().match(regex)
              }))
          if (hasDateValues) {
            column.valueFormatter = ({ value }) => {
              const momentValue = moment(value)
              if (momentValue.isValid()) {
                const formattedValue = momentValue
                  .local()
                  .format(value.length > 10 ? 'MM/DD/YYYY hh:mm' : 'MM/DD/YYYY')
                return formattedValue
              }
              return ''
            }
          }
          const hasIntegerValues = columnData.every((value) =>
            Number.isInteger(value)
          )
          if (hasIntegerValues) {
            column.valueFormatter = ({ value }) => {
              const formattedValue = Number(value).toLocaleString('en-US', {
                minimumFractionDigits: 0,
              })
              return formattedValue
            }
            column.align = key === 'Id' ? 'left' : 'right'
          }
          if (key === 'Image') {
            column.headerName = 'Icon'
            column.renderCell = ({ value }) => {
              return (
                <img src={value} alt="User avatar" className="user-avatar" />
              )
            }
            column.flex = 0.25
          }
          return column
        })
      setColumns(columnsFromData)
    }

    setCurrentRows(data)
  }, [data])

  const handleDelete = () => {
    const rowsToDelete = currentRows.filter((row) =>
      selectedRows.includes(row._uuid)
    )
    onDelete(rowsToDelete)
    setShowModal(false)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleShowModal = () => {
    setShowModal(true)
  }

  const checkboxSelectionProp = selectableRows
    ? { checkboxSelection: true }
    : {}

  return (
    <>
      <div className="data-grid-wrapper">
        {total && !columns.some((column) => column.field === 'Email') && (
          <SummaryDataGrid data={currentRows} total={total} />
        )}
        <StyledDataGrid
          autoHeight
          rows={currentRows}
          columns={columns}
          getRowId={(row) => row._uuid}
          className="data-grid2"
          getRowClassName={(params) =>
            `super-app-theme--${params.row._colorRow}`
          }
          components={{
            Toolbar: (props) => (
              <CustomToolbar
                {...props}
                onDelete={handleShowModal}
                disabled={selectedRows.length === 0}
              />
            ),
          }}
          {...checkboxSelectionProp}
          isRowSelectable={(params) =>
            selectableRows && !params.row.Status.includes('Onboarded')
          }
          onSelectionModelChange={(newSelectionModel) => {
            setSelectedRows(newSelectionModel)
          }}
        />
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete{' '}
          {currentRows
            .filter((row) => selectedRows.includes(row._uuid))
            .map((row) => row.Name)
            .join(', ')}
          ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            onClick={handleCloseModal}
            style={{ backgroundColor: '#6c757d', color: 'white' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDelete}
            style={{
              backgroundColor: '#FF902D',
              color: 'white',
              marginLeft: 10,
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FilterableDataTable
