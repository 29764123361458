import React, { useEffect } from "react";
import DownloadComponent from "../components/download/downloadComponent.js";
import { DOWNLOAD_APP_URL } from "../components/utils.js";

function DownloadPage() {
  const link = DOWNLOAD_APP_URL;

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(
      window.navigator.userAgent
    );

    if (isMobile) {
      window.location.href = link;
    }
  }, []);

  return <DownloadComponent />;
}

export default DownloadPage;
