import React, { useState, useEffect } from "react";
import {
  Container,
  ProgressBar,
  Button,
  Row,
  Col,
  Card,
  ListGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "../common/tools.css";
import { useLazyQuery, useMutation } from "@apollo/client";
import { LOAD_INVITATIONS } from "../../GraphQL/queries";
import {
  INVITE_USERS,
  UPDATE_INVITATION,
  DELETE_INVITATIONS,
} from "../../GraphQL/mutations";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FilterableDataTable from "../common/filterableDataTable";
import LoadingComponent from "../shared/loadingComponent";
import { ReactComponent as CreditCardIcon } from "bootstrap-icons/icons/credit-card.svg";
import { ReactComponent as PersonPlusIcon } from "bootstrap-icons/icons/person-plus.svg";
import { ReactComponent as GraphUpIcon } from "bootstrap-icons/icons/graph-up.svg";
import { ReactComponent as ClipboardIcon } from "bootstrap-icons/icons/clipboard.svg";
import { ReactComponent as ClipboardCheckIcon } from "bootstrap-icons/icons/clipboard-check.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tr } from "../labels";
import { useSelector } from "react-redux";

toast.configure();

function UsersComponent() {
  const selectedCompany = useSelector((state) => state.company.company);
  const profileObj = useSelector((state) => state.profile.profile);
  const { user } = profileObj || {};
  const [showEditName, setShowEditName] = useState(false);
  const [showInviteUsers, setShowInviteUsers] = useState(false);
  const [editedEmployee, setEditedEmployee] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [inviteLink, setInviteLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [currentCompanyId, setCurrentCompanyId] = useState(null);

  const copyClicked = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const computeInvitationStatus = (invitation) => {
    if (invitation.acceptedAt) {
      const date = moment
        .utc(invitation.acceptedAt)
        .local()
        .format("MMM DD, YY");
      return `Accepted on ${date}`;
    }
    const date = moment.utc(invitation.invitedAt).local().format("MMM DD, YY");
    return `Invited on ${date}`;
  };

  const formatDate = (dateString) => {
    return moment.utc(dateString).local().format("MMM DD, YY");
  };

  const computeStatus = (invitation) => {
    console.log(invitation);

    const employee = invitation?.user?.employee;
    const today = moment().startOf("day");

    if (moment(employee.dateOfOffboarding).isBefore(today)) {
      return `Offboarded on ${formatDate(employee.dateOfOffboarding)}`;
    }

    if (employee?.onboardedAt) {
      return `Onboarded on ${formatDate(employee.onboardedAt)}`;
    }

    if (employee?.signedUpAt) {
      return `Signed Up on ${formatDate(employee.signedUpAt)}`;
    }

    if (employee) {
      return "Not signed up yet";
    }

    return "Invitation pending...";
  };

  const computeStatusColor = (invitation) => {
    console.log(invitation);

    const employee = invitation?.user?.employee;
    const today = moment().startOf("day");

    if (moment(employee.dateOfOffboarding).isBefore(today)) {
      return "error";
    }

    if (employee?.onboardedAt) {
      return "success";
    }

    if (employee?.signedUpAt) {
      return "success";
    }

    if (employee) {
      return "info";
    }
    return "warning";
  };

  const computeStatusForContract = (invitation) => {
    const employee = invitation?.user?.employee;
    const today = moment().startOf("day");

    if (moment(employee.dateOfOffboarding).isBefore(today)) {
      return "Offboarded";
    }
    if (invitation.user && invitation.user.employee.parentId) {
      return "Dependent";
    }
    return "Policy Holder";
  };
  const computeStatusColorForContract = (invitation) => {
    const employee = invitation?.user?.employee;
    const today = moment().startOf("day");

    if (moment(employee.dateOfOffboarding).isBefore(today)) {
      return "error";
    }
    if (!employee?.signedUpAt) {
      return "error";
    }
    if (!employee?.onboardedAt) {
      return "warning";
    }
    if (employee.parentId) {
      return "info";
    }
    return "";
  };
  const handleCancel = () => {
    setShowEditName(false);
    setShowInviteUsers(false);
  };
  const handleEditNames = (e) => {
    e.preventDefault();
    var ret = [];
    for (const emp of tableData) {
      if (emp.Email === editedEmployee.Email) {
        ret.push({
          ...editedEmployee,
          Name: `${editedEmployee._firstName} ${editedEmployee._lastName}`,
          _firstName: editedEmployee._firstName,
          _lastName: editedEmployee._lastName,
        });
      } else {
        ret.push(emp);
      }
    }
    setShowEditName(false);
    onRunMutation(e, updateInvitation, "updateInvitation", {
      email: editedEmployee.Email,
      firstName: editedEmployee._firstName,
      lastName: editedEmployee._lastName,
    });
    console.log(ret);
    setTableData(ret);
  };

  const defaultAvatarImage =
    "https://cdn.healthmov.com/images/avatar-male.jpeg";
  const fetchInvitations = (data) => {
    if (data) {
      const { invitations } = data || [];
      var employees = [];
      for (const [idx, invitation] of Object.entries(invitations)) {
        const fn =
          (invitation.user && invitation.user.employee.firstName) ||
          invitation.firstName ||
          "";
        const ln =
          (invitation.user && invitation.user.employee.lastName) ||
          invitation.lastName ||
          "";
        const obj =
          selectedCompany.plan === "CONTRACT"
            ? {
                Id:
                  (invitation.user && invitation.user.employee.externalId) ||
                  parseInt(idx) + 1,
                Image: invitation.user?.pictureUrl || defaultAvatarImage,
                Name: `${fn} ${ln}`,
                _uuid: invitation.uuid,
                _firstName: fn,
                _lastName: ln,
                Email: invitation.user?.email || invitation.email,
                Birthdate: invitation.user?.employee?.birthdate,
                "Signed Up At": invitation.user
                  ? invitation.user.employee.signedUpAt
                  : null,
                "Onboarded At": invitation.user
                  ? invitation.user.employee.onboardedAt
                  : null,
                "Offboarding date":
                  invitation.user?.employee?.dateOfOffboarding,
                Status: computeStatusForContract(invitation),
                _colorRow: computeStatusColorForContract(invitation),
                "Last Active At": invitation.user?.employee?.lastViewHomeAt,
              }
            : {
                Image: invitation.user?.pictureUrl || defaultAvatarImage,
                Name: `${fn} ${ln}`,
                _uuid: invitation.uuid,
                _firstName: fn,
                _lastName: ln,
                Email: invitation.user?.email || invitation.email,
                Invitation: computeInvitationStatus(invitation),
                Status: computeStatus(invitation),
                _colorRow: computeStatusColor(invitation),
                "Last View Home At": invitation.user?.employee?.lastViewHomeAt,
              };
        employees.push(obj);
      }
      setTableData(employees);
      setInviteLink(
        `${process.env.REACT_APP_WEB_BASE_URL}accept_invitation?uuid=${selectedCompany?.id}`
      );
      setIsLoading(false);
    }
  };

  const [loadInvitations] = useLazyQuery(LOAD_INVITATIONS, {
    fetchPolicy: "no-cache",
    variables: { companyId: selectedCompany.id },
    onCompleted: (data) => {
      setEmails([]);
      fetchInvitations(data);
    },
    onError: (err) => {
      document.body.style.cursor = "default";
      toast.error(err.message);
    },
  });

  useEffect(() => {
    if (
      selectedCompany &&
      selectedCompany.id &&
      selectedCompany.id !== currentCompanyId
    ) {
      setCurrentCompanyId(selectedCompany.id);
      loadInvitations();
    }
  }, [selectedCompany, loadInvitations, currentCompanyId]);

  const onEdit = (e) => {
    const email = e.target.getAttribute("value");
    console.log(email);
    const employee = tableData.find((em) => em.Email === email);
    console.log(employee);
    setEditedEmployee(employee);
    setShowEditName(true);
  };

  const [inviteUsers] = useMutation(INVITE_USERS, { fetchPolicy: "no-cache" });
  const [updateInvitation] = useMutation(UPDATE_INVITATION, {
    fetchPolicy: "no-cache",
  });
  const [deleteInvitations] = useMutation(DELETE_INVITATIONS, {
    fetchPolicy: "no-cache",
  });
  const [tableData, setTableData] = useState([]);
  const [emails, setEmails] = useState([]);

  const onRunMutation = (
    e,
    method,
    methodName,
    variables,
    check,
    onComplete
  ) => {
    e && e.preventDefault();
    let error = null;
    if (check) {
      error = check(variables);
    }
    if (error) {
      toast.error(error);
      return;
    }

    document.body.style.cursor = "wait";
    method({
      variables: variables || {},
    })
      .then(({ data }) => {
        document.body.style.cursor = "default";
        if (data[methodName].error) {
          toast.error(data[methodName].error);
        } else {
          toast.success(data[methodName].success);
          if (onComplete) {
            onComplete();
          }
        }
      })
      .catch((err) => {
        document.body.style.cursor = "default";
        if (methodName in err) {
          toast.error(err[methodName].error);
        } else {
          toast.error(err.message);
        }
      });
  };

  const onDelete = (rows) => {
    const emails = rows.map((row) => row.Email);
    onRunMutation(
      null,
      deleteInvitations,
      "deleteInvitations",
      {
        emails: emails,
      },
      () => {},
      loadInvitations
    );
  };

  const conditionalRowStyles = [
    {
      when: (row) =>
        row.Invitation &&
        row.Invitation.indexOf("Accepted on") === 0 &&
        selectedCompany.plan !== "CONTRACT",
      style: {
        backgroundColor: "rgba(255, 144, 45, 0.3)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) =>
        row.Status &&
        row.Status.indexOf("Onboarded on") === 0 &&
        selectedCompany.plan === "CONTRACT",
      style: {
        backgroundColor: "rgba(255, 144, 45, 0.3)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) =>
        row.Status &&
        row.Status.indexOf("Off") === 0 &&
        selectedCompany.plan === "CONTRACT",
      style: {
        backgroundColor: "rgba(255, 144, 45, 0.8)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) =>
        row.Status &&
        row.Status.indexOf("Signed Up on") === 0 &&
        selectedCompany.plan === "CONTRACT",
      style: {
        backgroundColor: "rgba(255, 144, 45, 0.15)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row["Signed Up At"],
      style: {
        backgroundColor: "rgba(255, 144, 45, 0.15)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row["Onboarded At"],
      style: {
        backgroundColor: "rgba(255, 144, 45, 0.3)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const onInviteUsers = (e) => {
    setShowInviteUsers(false);
    onRunMutation(
      e,
      inviteUsers,
      "inviteUsers",
      {
        link: process.env.REACT_APP_WEB_BASE_URL + "accept_invitation",
        emails: emails,
      },
      () => {},
      loadInvitations
    );
  };
  const onClickInviteUsers = (e) => {
    setShowInviteUsers(true);
  };

  const onBuyMoreSeats = () => {
    window.location.href = "/account-billing?buy=true";
  };
  const onReportsClicked = () => {
    window.location.href = "/reports-all";
  };

  const rowDisabledCriteria = (row) => user && row.Email === user.email;

  return isLoading ? (
    <LoadingComponent />
  ) : (
    <>
      <Container fluid="md">
        <Row>
          {!selectedCompany || selectedCompany.plan === "CONTRACT" ? (
            <></>
          ) : (
            <>
              <Col sm="4">
                <Card>
                  <Card.Body>
                    <Card.Title>{tr("availableSeatsLabel")}</Card.Title>
                    <div className="card-content">
                      <ListGroup className="seats-list" variant="flush">
                        <ProgressBar
                          striped
                          now={
                            (100 * tableData.length) / selectedCompany.maxSeats
                          }
                        />
                        <ListGroup.Item className="nb-seats-used">
                          {tableData.length} {tr("ofLabel")}{" "}
                          {Math.max(tableData.length, selectedCompany.maxSeats)}{" "}
                          {tr("usedLabel")}
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                    <Card.Link href="#">
                      <Button variant="primary main" onClick={onBuyMoreSeats}>
                        <CreditCardIcon /> {tr("buyMoreSeatsLabel")}
                      </Button>
                    </Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="4">
                <Card>
                  <Card.Body>
                    <Card.Title>{tr("invitedUsersLabel")}</Card.Title>
                    <div className="card-content">
                      <Card.Text className="payment-due-date">
                        {tableData.length}
                      </Card.Text>
                    </div>
                    <Card.Link href="#">
                      <Button
                        variant="primary main"
                        onClick={onClickInviteUsers}
                      >
                        <PersonPlusIcon /> {tr("inviteUsersLabel")}
                      </Button>
                    </Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="4">
                <Card>
                  <Card.Body>
                    <Card.Title> {tr("signedUpUsersLabel")} </Card.Title>
                    <div className="card-content">
                      <Card.Text className="current-bill">
                        {selectedCompany.nbSignedUpInvitations}
                      </Card.Text>
                    </div>
                    <Card.Link href="#">
                      <Button variant="primary main" onClick={onReportsClicked}>
                        <GraphUpIcon />
                        {tr("checkUsageLabel")}
                      </Button>
                    </Card.Link>
                  </Card.Body>
                </Card>
              </Col>
            </>
          )}
        </Row>
        <br />
        <Row>
          <FilterableDataTable
            title="Users"
            data={tableData}
            onDelete={selectedCompany.plan !== "CONTRACT" ? onDelete : null}
            onEdit={onEdit}
            selectableRows={selectedCompany.plan !== "CONTRACT"}
            conditionalRowStyles={conditionalRowStyles}
            rowDisabledCriteria={rowDisabledCriteria}
          />
        </Row>
      </Container>

      <Modal show={showEditName} onHide={handleCancel}>
        <Modal.Header>edit name</Modal.Header>
        <form>
          <Modal.Body>
            <div>
              <label className="form-label" htmlFor="first-name">
                First Name:
              </label>
              <input
                className="form-control"
                type="text"
                id="first-name"
                value={editedEmployee._firstName}
                onChange={(event) =>
                  setEditedEmployee({
                    ...editedEmployee,
                    _firstName: event.target.value,
                  })
                }
              />
            </div>
            <div>
              <label className="form-label" htmlFor="last-name">
                Last Name:
              </label>
              <input
                className="form-control"
                type="text"
                id="last-name"
                value={editedEmployee._lastName}
                onChange={(event) =>
                  setEditedEmployee({
                    ...editedEmployee,
                    _lastName: event.target.value,
                  })
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>
              cancel
            </Button>
            <Button variant="success" onClick={handleEditNames}>
              save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showInviteUsers} onHide={handleCancel} size="lg">
        <Modal.Header closeButton>{tr("inviteUsersLabel")}</Modal.Header>
        <form>
          <Modal.Body>
            <div>
              <label className="form-label" htmlFor="link">
                {tr("inviteByLinkLabel")}
              </label>
              <div className="invite-link">
                <input
                  className="form-control"
                  id="inviteLink"
                  name="inviteLink"
                  disabled
                  value={inviteLink}
                />
                <CopyToClipboard text={inviteLink} onCopy={copyClicked}>
                  <Button variant="light">
                    {isCopied ? (
                      <>
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          show={true}
                          overlay={<Tooltip id={`tooltip-top`}>Copied</Tooltip>}
                        >
                          <ClipboardCheckIcon />
                        </OverlayTrigger>
                      </>
                    ) : (
                      <ClipboardIcon />
                    )}
                  </Button>
                </CopyToClipboard>
              </div>
              <label className="form-label" htmlFor="terms-checkbox">
                {tr("copyPasteEmailsLabel")}
              </label>
              <ReactMultiEmail
                placeholder={tr("listOfEmailsLabel")}
                emails={emails}
                onChange={(_emails) => {
                  setEmails(_emails);
                }}
                autoFocus={true}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      <div data-tag-item>{email}</div>
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  );
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary main   " onClick={onInviteUsers}>
              {tr("inviteUsersLabel")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default UsersComponent;
